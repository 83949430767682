import Link from 'next/link';
import { AbstractedLinkProps } from '@pelando/components';
import { isSEOLinkWorkaroundEnabled } from '@/presentation/services/featureFlags';
import { isCouponPageWithSlug } from '@/presentation/services/seo';

function NextLink({ href, children, ...props }: AbstractedLinkProps) {
  const useLinkWorkaround = isSEOLinkWorkaroundEnabled();

  const shouldUseLinkWorkaround =
    useLinkWorkaround && isCouponPageWithSlug(href || '');

  if (shouldUseLinkWorkaround) {
    return (
      <a data-testid="a-tag" href={href || ''} {...props}>
        {children}
      </a>
    );
  }
  return (
    <Link href={String(href)} passHref prefetch={false} legacyBehavior>
      <a {...props}>{children}</a>
    </Link>
  );
}

export default NextLink;
