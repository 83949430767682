import { FormState } from '..';

export const formatCPF = (value: string) =>
  value
    .replace(/\D/g, '')
    .replace(/^(\d{3})(\d)/, '$1.$2')
    .replace(/^(\d{3}\.\d{3})(\d)/, '$1.$2')
    .replace(/^(\d{3}\.\d{3}\.\d{3})(\d)/, '$1-$2')
    .slice(0, 14);

export const isValidCPF = (cpf: string): boolean => {
  const newCpf = cpf.replace(/\D/g, '');

  if (newCpf.length !== 11 || /^(\d)\1+$/.test(newCpf)) {
    return false;
  }

  const calculateDigit = (base: string, factor: number): number => {
    const total = base
      .split('')
      .reduce(
        (sum, num, index) => sum + parseInt(num, 10) * (factor - index),
        0
      );
    return ((total * 10) % 11) % 10;
  };

  const base = newCpf.slice(0, 9);
  const digit1 = calculateDigit(base, 10);
  const digit2 = calculateDigit(base + digit1, 11);

  return (
    digit1 === parseInt(newCpf[9], 10) && digit2 === parseInt(newCpf[10], 10)
  );
};

export const isValidForm = (formData: FormState): boolean => {
  const { name, cpf, pixKey } = formData;

  if (!name.trim()) return false;
  if (!pixKey.trim()) return false;
  if (!cpf.trim() || !isValidCPF(cpf)) return false;

  return true;
};
