/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef } from 'react';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import { VoteKind } from '@pelando/components';
import { getFeedPage } from '@/infra/api/offers/helpers/getFeedPage';
import {
  isNewFeedPaginationEnabled,
  isSeasonalFeedEnabled,
} from '@/presentation/services/featureFlags';
import { isExploreCommunitiesSlug } from '@/presentation/services/exploreCommunities';
import getCommunityFeed from '@/infra/api/offers/helpers/getCommunityFeed';
import { fetchCommunityBySlug } from '@/infra/api/communities/helpers/getCommunityBySlug';
import { Community } from '@/domain/entities/Communities';
import { Categories } from '@/presentation/services/categories';
import { useRefetchHottestFeed } from '@/presentation/pages/public/feed/hooks/useRefetchHottestFeed';
import {
  FIRST_FEED_PAGE,
  isValidFeedPage,
  LAST_FEED_PAGE,
  parseFeedQueryParams,
} from '@/presentation/services/feed';
import { useCommunityFeedCache } from '@/presentation/pages/public/feed/hooks/useComunityFeedCache';
import { useInitialFeedPage } from '@/presentation/pages/public/feed/hooks/useInitialFeedPage';
import { Langs } from '@/locales';
import FeedPageContainer, {
  ChangeFilterOptions,
  DealsListContent,
  TABS_CONTAINER_ID,
} from '@/presentation/pages/public/feed';
import isSpecialFeedTab, {
  SpecialFeedTab,
  SpecialFeedTabSlugEnUS,
  SpecialFeedTabSlugPtBR,
  getFeedTabFromPath,
  getFeedTabFromSlug,
  getTabSlugFromFeedTab,
  isValidFeedSlug,
  slugEnUStoPtBR,
} from '@/presentation/services/feedTabsCategories';
import {
  getExploreItemsTitleBySlug,
  isExploreSlug,
} from '@/presentation/services/exploreCategories';
import { EventCategory, FeedEvents } from '@/domain/events/analytics';
import { FeaturedOffer, FeedDeal, OfferStatus } from '@/domain/entities/Offer';
import { emitTrackingEvent } from '@/infra/analytics/emitTrackingEvent';
import getTabNameBySortOption, {
  getFeedTabNameByTab,
} from '@/presentation/services/events/feed';
import cachePoliciesLoader from '@/server/loaders/cachePolicies';
import FeedSEO from '@/presentation/pages/public/feed/components/FeedSEO';
import { OfferOptions } from '@/infra/api/votes/types/Vote';
import { useIdentifyAuthRoutes } from '@/presentation/hooks/useIdentifyAuthRoutes';
import { useEnhancedIdentifyConfirmEmailRoutes } from '@/presentation/hooks/useIdentifyConfirmEmailRoutes';
import {
  FeedQueries,
  OfferQueryPageInfo,
} from '@/infra/api/offers/types/shared';
import { getFeaturedStores } from '@/infra/api/stores/helpers/getFeaturedStores';
import { FeaturedStores } from '@/domain/entities/Stores';
import { useApiCache } from '@/presentation/hooks/useApiCache';
import { useFeedCache } from '@/presentation/pages/public/feed/hooks/useFeedCache';
import { removeVote, userOfferVotes, vote } from '@/infra/api/votes/endpoints';
import { getSpecialFeed } from '@/infra/api/offers/helpers/getSpecialFeed';
import { UserFilterPreferenceVariables } from '@/infra/api/filters/types/UserFilterPreference';
import useLoadFeed from '@/presentation/pages/public/feed/hooks/useLoadFeed';
import {
  getUserFilterPreference,
  updateUserFilterPreference,
} from '@/infra/api/filters/endpoints';
import { FilterOptions, OfferSortOption } from '@/domain/entities/Filter';
import { useCurrentUserCache } from '@/presentation/hooks/useCurrentUserCache';
import { getRecombeeActivatedEventParam } from '@/infra/events/recombee';
import {
  filterExpiredOffers,
  redirectToCommunity,
} from '@/presentation/services/utils';
import useScrollToPagePosition from '@/presentation/hooks/useScrollToPagePosition';
import dynamic from 'next/dynamic';
import { PageLayout } from '@/customNextTypes';
import {
  ExploreTab,
  ExploreTabByLocale,
} from '@/presentation/pages/public/feed/components/ExploreFeedTabs';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { useAlertSuggestionsModal } from '@/presentation/hooks/useShowAlertSuggestionsModal';
import { ActiveCampaign } from '@/domain/entities/Campaigns';
import { getActiveCampaignHelper } from '@/infra/api/campaigns/helpers/getActiveCampaigns';

const FeedLayout = dynamic(() =>
  import('@/presentation/pages/public/Home/layout').then((mod) => mod.default)
);

const specialFeedQueryBySpecialTab = {
  [SpecialFeedTab.COMMENTED]: FeedQueries.COMMENTED,
  [SpecialFeedTab.FEATURED]: FeedQueries.FEATURED,
  [SpecialFeedTab.HOTTEST]: FeedQueries.HOTTEST,
  [SpecialFeedTab.RECENT]: FeedQueries.RECENT,
};

type FeaturedOffersProps =
  | {
      edges: FeaturedOffer[];
      pageInfo: OfferQueryPageInfo;
    }
  | undefined;

type FeedPageProps = {
  slug: string;
  feedOffers: FeaturedOffersProps;
  featuredStores?: FeaturedStores[];
  community?: Community;
  googleClientId?: string;
  page: number;
  previousPage: string | null;
  activeCampaign: ActiveCampaign;
};

function FeedPage({
  slug,
  feedOffers,
  featuredStores,
  community,
  googleClientId,
  page,
  previousPage,
  activeCampaign,
}: FeedPageProps) {
  const { replace, query, asPath, locale } = useRouter();
  const { t } = useTranslation('feed');
  const isFeedPaginationEnabled = isNewFeedPaginationEnabled();

  const { triggerAlertModal } = useAlertSuggestionsModal(previousPage);

  const categorySlug = (query.slug as string) || slug;
  const currentSlugRef = useRef(categorySlug);

  const hasSeasonalFeed = activeCampaign && isSeasonalFeedEnabled();

  // TODO: Refatorar feed para melhorar o uso da paginação e do cache
  const { initialPage, resetInitialPageValue, isInitialPageLoaded } =
    useInitialFeedPage(page);

  const initialTab =
    getFeedTabFromSlug(categorySlug, locale as Langs) ||
    SpecialFeedTab.FEATURED;

  const isSpecialTab = isSpecialFeedTab(categorySlug, locale as Langs);
  const { loggedUser: currentUser, loading } = useCurrentUserCache();

  const currentFilter = useApiCache(getUserFilterPreference.cache$);

  const isLogged = !!currentUser;

  const { sortBy, hideExpired, hottestFeedPeriod } = currentFilter || {};

  useEffect(() => {
    setTimeout(() => {
      triggerAlertModal();
    }, 10);
  }, []);

  const { recombeeQueryFailed } = useLoadFeed({
    isSpecialTab,
    feedOffers,
    initialTab,
  });

  useRefetchHottestFeed({
    initialPage,
    isInitialPageLoaded,
  });

  const { scrollPosition } = useScrollToPagePosition();

  useEffect(() => {
    window.scrollTo({ top: scrollPosition });
  }, [scrollPosition]);

  useEffect(() => {
    const offerIds =
      feedOffers?.edges.map((deal: { id: string }) => deal.id) || [];

    if (!userOfferVotes.cache$.value)
      userOfferVotes.requestAsPromise({ offerIds });
  }, [feedOffers]);

  useIdentifyAuthRoutes({
    loading,
    isLogged,
    pathname: asPath,
  });

  useEnhancedIdentifyConfirmEmailRoutes();

  const isFetchingMoreAlready = useRef(false);

  const scrollToTabsContainer = useCallback(() => {
    const feedTabsContainer = document.getElementById(TABS_CONTAINER_ID);
    if (hasSeasonalFeed) {
      window.scrollTo({ top: scrollPosition });
    } else {
      feedTabsContainer?.scrollIntoView();
    }
  }, []);

  const resetToInitalPage = () => {
    if (!isFeedPaginationEnabled) return;

    const newQuery = { ...query };
    delete newQuery.page;

    replace({ query: newQuery }, undefined, { shallow: true });
    resetInitialPageValue();
  };

  const handleTabChange = async (tab: SpecialFeedTab) => {
    scrollToTabsContainer();
    resetToInitalPage();

    emitTrackingEvent({
      name: FeedEvents.ClickToSelectFeedMenu,
      category: EventCategory.Feed,
      extra: {
        option: getFeedTabNameByTab(tab),
        recombeeActived: getRecombeeActivatedEventParam(),
      },
    });

    const slug = getTabSlugFromFeedTab(tab, locale as Langs);

    const filter = { hideExpired, hottestFeedPeriod };
    await getSpecialFeed({
      feed: specialFeedQueryBySpecialTab[tab],
      filter,
    });
    if (
      slug === SpecialFeedTabSlugPtBR.FEATURED ||
      slug === SpecialFeedTabSlugEnUS.FEATURED
    ) {
      replace('/', undefined, { shallow: true });
    } else {
      replace(slug, undefined, { shallow: true });
    }
  };

  const GetSpecialFeedContent = (
    tab: SpecialFeedTab
  ): DealsListContent<FeedDeal> => {
    const {
      feedOffersCache,
      loading,
      isPaginationLoading: paginationLoading,
      totalPages,
    } = useFeedCache({ tab, isLogged, recombeeQueryFailed }) || {};
    const { edges, pageInfo } = feedOffersCache || {};
    const filter = { hideExpired, hottestFeedPeriod };
    const after = pageInfo?.endCursor;
    const hasNextPage = pageInfo?.hasNextPage;

    if (currentFilter?.hideExpired) {
      if (edges) filterExpiredOffers(edges as FeaturedOffer[]);
    }

    return {
      deals: (edges as FeedDeal[]) || (feedOffers?.edges as FeedDeal[]),
      isLoading: loading,
      hasNextPage,
      totalPages: totalPages ?? LAST_FEED_PAGE,
      isPaginationLoading: paginationLoading,
      refetch: async () => {
        scrollToTabsContainer();
        await getSpecialFeed({
          feed: specialFeedQueryBySpecialTab[tab],
          filter,
          recombeeQueryFailed,
          page: initialPage,
        });
      },
      fetchMore: async () => {
        if (
          isFetchingMoreAlready.current ||
          !hasNextPage ||
          paginationLoading
        ) {
          return;
        }
        isFetchingMoreAlready.current = true;
        try {
          await getSpecialFeed({
            feed: specialFeedQueryBySpecialTab[tab],
            filter,
            after,
            recombeeQueryFailed,
          });
        } finally {
          isFetchingMoreAlready.current = false;
        }
      },
    };
  };

  const GetExploreDealContent = (): DealsListContent => {
    const { edges, loading, pageInfo, paginationLoading, offsetBasedPageInfo } =
      useCommunityFeedCache();

    const filter = { hideExpired, sortBy, hottestFeedPeriod };

    if (currentFilter?.hideExpired) {
      if (edges) filterExpiredOffers(edges as FeaturedOffer[]);

      if (feedOffers?.edges) filterExpiredOffers(feedOffers?.edges);
    }

    return {
      deals: (edges as FeedDeal[]) || (feedOffers?.edges as FeedDeal[]),
      hasNextPage: pageInfo?.hasNextPage || false,
      totalPages: offsetBasedPageInfo?.totalPages ?? LAST_FEED_PAGE,
      isLoading: loading,
      isPaginationLoading: paginationLoading,
      refetch: async () => {
        scrollToTabsContainer();
        await getCommunityFeed({
          communitySlug: categorySlug,
          filter: filter as FilterOptions,
          page: initialPage,
        });
      },
      fetchMore: async () => {
        if (paginationLoading) return;
        await getCommunityFeed({
          communitySlug: categorySlug,
          filter: filter as FilterOptions,
          after: pageInfo?.endCursor,
        });
      },
    };
  };

  const updateFilterChange = async (filters: ChangeFilterOptions) => {
    const { hideExpired, hottestFeedPeriod, sortBy } = filters;
    const variables: UserFilterPreferenceVariables = {
      input: {
        preferences: {
          hottestFeedPeriod,
          sortBy,
          status: hideExpired ? OfferStatus.ACTIVE : undefined,
        },
      },
    };

    await updateUserFilterPreference.requestAsPromise(variables);
  };

  const refetchContentOnFilterChange = async (filters: ChangeFilterOptions) => {
    const { hideExpired, hottestFeedPeriod, sortBy } = filters;

    const isRecentTab = sortBy === OfferSortOption.CREATED_AT || !sortBy;

    if (isSpecialTab) {
      const tab = getFeedTabFromPath(asPath, locale as Langs);

      const filter = {
        hideExpired,
        sortBy,
        hottestFeedPeriod,
      };

      await getSpecialFeed({
        feed: specialFeedQueryBySpecialTab[tab],
        filter,
        recombeeQueryFailed,
        page: initialPage,
      });

      return;
    }

    const filter = {
      hideExpired,
      sortBy,
      hottestFeedPeriod,
    };

    if (!isRecentTab) {
      resetToInitalPage();
    }

    await getCommunityFeed({
      communitySlug: categorySlug,
      filter: filter as FilterOptions,
      page: isRecentTab ? initialPage : undefined,
    });
  };

  const refetchFilterContent = async ({
    sortBy: sortByCurrent,
    ...filters
  }: ChangeFilterOptions) => {
    const updatedFilters = {
      ...currentFilter,
      ...filters,
      sortBy: sortByCurrent || sortBy,
      status: hideExpired ? OfferStatus.ACTIVE : undefined,
    };

    await refetchContentOnFilterChange(updatedFilters);
  };

  const onUpdateFilter = async (
    { sortBy: sortByCurrent, ...filters }: ChangeFilterOptions,
    exploreTab?: ExploreTab
  ) => {
    emitTrackingEvent({
      name: FeedEvents.ClickToSelectFeedMenu,
      category: EventCategory.Feed,
      extra: {
        option: `category_tabs_${getTabNameBySortOption(sortByCurrent)}`,
        recombeeActived: getRecombeeActivatedEventParam(),
      },
    });

    const updatedFilters = {
      ...currentFilter,
      ...filters,
      sortBy: sortByCurrent || sortBy,
      status: hideExpired ? OfferStatus.ACTIVE : undefined,
    };

    if (isLogged) {
      await updateFilterChange(updatedFilters);
    } else {
      getUserFilterPreference.cache$.next({
        ...currentFilter,
        ...filters,
        sortBy: sortByCurrent || sortBy,
      });
    }
    await refetchFilterContent(updatedFilters);

    if (exploreTab) {
      replace(`${slug}${t(ExploreTabByLocale[exploreTab || 0])}`, undefined, {
        shallow: true,
      });
    }
  };

  const handleChangeFilter = (
    filters: ChangeFilterOptions,
    exploreTab?: ExploreTab
  ) => {
    onUpdateFilter(filters, exploreTab);
    scrollToTabsContainer();
  };

  useEffect(() => {
    if (getUserFilterPreference.cache$.value || !isInitialPageLoaded) return;

    getUserFilterPreference.requestAsPromise().then(({ data }) => {
      const { status, hottestFeedPeriod, sortBy } = data?.preferences || {};

      if (status === OfferStatus.ACTIVE) {
        onUpdateFilter({
          hideExpired: true,
          hottestFeedPeriod,
          sortBy,
        });
      }
    });
  }, [isInitialPageLoaded]);

  const resetToInitialPageWhenSlugChanges = useCallback(() => {
    if (currentSlugRef.current === categorySlug) return;
    if (isSpecialTab || !isFeedPaginationEnabled) return;

    resetToInitalPage();
    currentSlugRef.current = categorySlug;
  }, [categorySlug]);

  useEffect(() => {
    resetToInitialPageWhenSlugChanges();
  }, [resetToInitialPageWhenSlugChanges]);

  const handleVote = async ({
    id,
    voteKind,
    options,
  }: {
    id: string;
    voteKind: VoteKind;
    options?: OfferOptions;
  }) => {
    vote.requestAsPromise({
      id,
      voteKind,
      options,
    });
  };

  const handleRemoveVote = ({ id }: { id: string }) => {
    removeVote.requestAsPromise({ id });
  };

  const exploreCategoryTitle = getExploreItemsTitleBySlug(categorySlug);

  return (
    <>
      <FeedSEO categorySlug={categorySlug} />
      <FeedPageContainer
        initialTab={initialTab}
        categorySlug={categorySlug}
        community={community}
        exploreCategoryTitle={exploreCategoryTitle}
        onUpdateActiveFeedTab={handleTabChange}
        exploreContent={GetExploreDealContent()}
        featuredContent={GetSpecialFeedContent(SpecialFeedTab.FEATURED)}
        recentsContent={GetSpecialFeedContent(SpecialFeedTab.RECENT)}
        commentedContent={GetSpecialFeedContent(SpecialFeedTab.COMMENTED)}
        hottestContent={GetSpecialFeedContent(SpecialFeedTab.HOTTEST)}
        onChangeFilter={handleChangeFilter}
        refetchFilterContent={refetchFilterContent}
        initialFilters={currentFilter as FilterOptions}
        onVote={handleVote}
        onRemoveVote={handleRemoveVote}
        featuredStores={featuredStores}
        currentUser={currentUser}
        googleClientId={googleClientId}
        initialPage={initialPage}
        activeCampaign={activeCampaign}
      />
    </>
  );
}

FeedPage.getLayout = ((page) => <FeedLayout>{page}</FeedLayout>) as PageLayout;

const EnhancedFeedPage = FeedPage;

export const getServerSideProps: GetServerSideProps = async ({
  locale,
  query,
  params,
  res,
  req,
}) => {
  res.setHeader('Cache-Control', cachePoliciesLoader('/feed'));
  const { slug } = params as { slug: string };
  const { page } = parseFeedQueryParams(query);

  const previousPage = req.headers.referer || null;

  const GOOGLE_CLIENT_ID = process.env.AUTH_GOOGLE_APP_ID || null;

  const isValidSlug = isValidFeedSlug(slug, locale as Langs);

  const isCommunityFeed = isExploreCommunitiesSlug(slug);

  const isCategorySlug = isExploreSlug(slug);

  const isNewPaginationEnabled = isNewFeedPaginationEnabled();

  const isValidPage = isValidFeedPage(page);

  if (isCategorySlug) {
    const redirectCategoryToCommunity = redirectToCommunity(slug as Categories);
    return { props: {}, redirect: { ...redirectCategoryToCommunity } };
  }

  if (!isValidSlug || !isValidPage) {
    return {
      notFound: true,
    };
  }

  const communityBySlugRequest = isCommunityFeed
    ? fetchCommunityBySlug
    : () => null;

  const featuredStoresRequest = isSpecialFeedTab(slug, locale as Langs)
    ? getFeaturedStores
    : () => null;

  const shouldApplyPagination =
    isNewPaginationEnabled && page > FIRST_FEED_PAGE;

  const feedPage = shouldApplyPagination ? page : undefined;
  const translatedSlug =
    locale === 'en-US' ? slugEnUStoPtBR(isCommunityFeed, slug) : slug;

  // TODO: Add error handling
  const [{ data: feedOffers }, featuredStores, community, activeCampaign] =
    await Promise.all([
      getFeedPage(translatedSlug, feedPage),
      featuredStoresRequest(),
      communityBySlugRequest({ slug: translatedSlug }),
      getActiveCampaignHelper(),
    ]);

  return {
    props: {
      slug,
      page,
      feedOffers,
      featuredStores,
      community,
      googleClientId: GOOGLE_CLIENT_ID,
      previousPage,
      activeCampaign,
    },
  };
};

export default EnhancedFeedPage;
