import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import {
  isComparatorHomeEnabled,
  isHeaderCommunitiesDisabled,
} from '@/presentation/services/featureFlags';
import { HomeNavigationItem } from '@/presentation/components/BottomNavigation/components/HomeNavigationItem';
import { ComparatorNavigationItem } from '@/presentation/components/BottomNavigation/components/ComparatorNavigationItem';
import {
  PathOptions,
  useCurrenActiveItemByPath,
} from '@/presentation/components/BottomNavigation/hooks/useCurrenActiveItemByPath';
import { emitTrackingEvent } from '@/infra/analytics/emitTrackingEvent';
import {
  CommunityEvents,
  EventCategory,
  GeneralProductEvents,
  ProductComponentNames,
} from '@/domain/events/analytics';
import { useModal } from '@pelando/components';
import dynamic from 'next/dynamic';
import { useAlertsCount } from '../../hooks/useAlertsCount';
import { BOTTOM_NAVIGATION_HEIGHT } from './style';
import BottomNavigation from './component';
import { CommunitiesNavigationItem } from './components/CommunitiesNavigationItem';

const AlertsNavigationItem = dynamic(() =>
  import(
    '@/presentation/components/BottomNavigation/components/AlertsNavigationItem'
  ).then((mod) => mod.AlertsNavigationItem)
);

const AddOfferNavigationItem = dynamic(() =>
  import(
    '@/presentation/components/BottomNavigation/components/AddOfferNavigationItem'
  ).then((mod) => mod.AddOfferNavigationItem)
);

export { BOTTOM_NAVIGATION_HEIGHT };

export type BottomNavigationEnhancedProps = {
  className?: string;
  onClickMenu: () => void;
  closeMenu: () => void;
  innerRef?: React.Ref<HTMLDivElement>;
  categoriesOpen?: boolean;
};

const getCommunityCategoryItem = ({
  isCommunityDisabled,
  handleCommunityOrCategoryClick,
}: {
  isCommunityDisabled: boolean;
  handleCommunityOrCategoryClick: () => void;
}) => {
  if (isCommunityDisabled) return null;

  return (
    <CommunitiesNavigationItem
      key="communities"
      onClick={handleCommunityOrCategoryClick}
    />
  );
};

function BottomNavigationEnhanced({
  className,
  onClickMenu,
  closeMenu,
  innerRef,
  categoriesOpen,
}: BottomNavigationEnhancedProps) {
  const { reload } = useRouter();
  const { count: alertsCount } = useAlertsCount();
  const { showModal, closeModal } = useModal();

  const { activeIndex, activePath } = useCurrenActiveItemByPath();

  const isCommunityDisabled = isHeaderCommunitiesDisabled();

  const handleAlertsClick = useCallback(() => {
    closeMenu();
    const isOnAlertsPage = activePath === PathOptions.Alerts;
    if (!isOnAlertsPage || categoriesOpen) return;
    reload();
  }, [activePath, categoriesOpen, closeMenu, reload]);

  const handleComparatorClick = useCallback(() => {
    emitTrackingEvent({
      category: EventCategory.GeneralProduct,
      name: GeneralProductEvents.ClickToGoToComparatorHome,
      extra: {
        component_source: ProductComponentNames.BottomNavigation,
      },
    });
    closeMenu();
  }, [closeMenu]);

  const handleCommunityOrCategoryClick = useCallback(() => {
    onClickMenu();
    emitTrackingEvent({
      category: EventCategory.Community,
      name: CommunityEvents.ClickToGoBackToCommunities,
      extra: undefined,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeModal, onClickMenu, showModal]);

  const routeLinks = useMemo(
    () =>
      [
        <HomeNavigationItem key={PathOptions.Home} />,
        <AddOfferNavigationItem key={PathOptions.Post} />,
        isComparatorHomeEnabled() ? (
          <ComparatorNavigationItem
            key={PathOptions.Comparator}
            onClick={handleComparatorClick}
          />
        ) : null,
        <AlertsNavigationItem
          count={alertsCount}
          key={PathOptions.Alerts}
          onClick={handleAlertsClick}
        />,
        getCommunityCategoryItem({
          isCommunityDisabled,
          handleCommunityOrCategoryClick,
        }),
      ].filter((item) => !!item) as JSX.Element[],
    [
      handleComparatorClick,
      alertsCount,
      handleAlertsClick,
      handleCommunityOrCategoryClick,
      isCommunityDisabled,
    ]
  );

  return (
    <BottomNavigation
      innerRef={innerRef}
      className={className}
      activeIndex={categoriesOpen ? routeLinks.length - 1 : activeIndex}
      navigationItems={routeLinks}
    />
  );
}

export default BottomNavigationEnhanced;
