import {
  getDefaultDescription,
  getDefaultTitle,
  getRssUrl,
} from '@/presentation/services/seo/index';
import { getExploreItemsTitleBySlug } from '@/presentation/services/exploreCategories';
import {
  ExploreCommunities,
  getExploreCommunitiesItemsTitleBySlug,
} from '@/presentation/services/exploreCommunities';
import isSpecialFeedTab, {
  SpecialFeedTabSlugEnUS,
  SpecialFeedTabSlugPtBR,
  getSpecialFeedTitleBySlug,
} from '@/presentation/services/feedTabsCategories';
import { getFeedUrl, isFeedSlugValid } from '@/presentation/services/urls/feed';
import { isNewFeedPaginationEnabled } from '@/presentation/services/featureFlags';
import { getCanonicalUrl } from '@/presentation/services/urls';
import { Langs, getTranslatedString } from '@/locales';

const getCommunityTitle = (slug: string, locale: string): string =>
  getSpecialFeedTitleBySlug(slug, locale as Langs) ||
  getExploreItemsTitleBySlug(slug) ||
  getExploreCommunitiesItemsTitleBySlug(slug as ExploreCommunities, locale);

type GetNewFeedTitleParams = {
  slug: string;
  page?: number;
  locale?: string;
};

export const getNewFeedTitle = ({
  slug,
  page = 1,
  locale,
}: GetNewFeedTitleParams) => {
  if (
    !isFeedSlugValid(slug, locale as Langs) ||
    slug === SpecialFeedTabSlugPtBR.FEATURED ||
    slug === SpecialFeedTabSlugEnUS.RECENT
  ) {
    return getDefaultTitle();
  }

  const communityTitle = getCommunityTitle(slug, locale || '');

  const pageSuffix =
    page > 1 && isNewFeedPaginationEnabled()
      ? getTranslatedString('feed', 'seo-page-suffix', page)
      : '';

  const slugPrefix = `${communityTitle}:`;

  return getTranslatedString('feed', 'seo-title', slugPrefix, pageSuffix);
};

type GetFeedDescriptionParams = {
  slug?: string;
  locale?: string;
};

export const getFeedDescription = ({
  locale,
  slug,
}: GetFeedDescriptionParams) => {
  if (
    !slug ||
    !isFeedSlugValid(slug, locale as Langs) ||
    slug === SpecialFeedTabSlugPtBR.FEATURED ||
    slug === SpecialFeedTabSlugEnUS.RECENT
  ) {
    return getDefaultDescription();
  }

  const communityTitle = getCommunityTitle(slug, locale || '');

  return getTranslatedString('feed', 'seo-description', communityTitle);
};

export const getHomeFeedRss = (slug: string, locale: Langs) => {
  if (!isFeedSlugValid(slug, locale) || !slug) return undefined;

  if (isSpecialFeedTab(slug, locale)) {
    return getRssUrl(
      {
        [SpecialFeedTabSlugPtBR.FEATURED]: 'highlights',
        [SpecialFeedTabSlugPtBR.RECENT]: 'recents',
        [SpecialFeedTabSlugPtBR.HOTTEST]: 'hottest',
        [SpecialFeedTabSlugPtBR.COMMENTED]: 'commented',
        [SpecialFeedTabSlugEnUS.FEATURED]: 'highlights',
        [SpecialFeedTabSlugEnUS.RECENT]: 'recents',
        [SpecialFeedTabSlugEnUS.HOTTEST]: 'hottest',
        [SpecialFeedTabSlugEnUS.COMMENTED]: 'commented',
      }[slug]
    );
  }

  return getRssUrl(`cat/${slug}`);
};

export const getFeedCanonical = ({
  slug,
  page = 1,
  locale,
}: {
  slug: string;
  page?: number;
  locale: string;
}) => {
  if (!isFeedSlugValid(slug, locale) || !slug) return undefined;

  const isFeaturedCategory =
    slug === SpecialFeedTabSlugPtBR.FEATURED ||
    slug === SpecialFeedTabSlugEnUS.FEATURED;

  const pathname = !isFeaturedCategory
    ? getFeedUrl(slug, { page })
    : getFeedUrl();

  return getCanonicalUrl(pathname);
};
