import { OFFSET_BASED_PAGE_INFO_FRAGMENT } from '../../offers/queries/shared';
import {
  CASHBACKS_FRAGMENT,
  CASHBACKS_WITHDRAWAL_FRAGMENT,
} from '../fragments';

export const GET_CASHBACKS_QUERY = `
query Cashbacks(
    $limit: Int,
    $page: Int
) {
    me {
        cashbackTransactions(
            limit: $limit,
            page: $page
        ) {
            edges {
                ...CashbackWithdrawalFragment 
                ...CashbackUserInfoFragment 
            }
            pageInfo {
                ...OffsetBasedPaginationInfoFragment
            }
        }
    }
}
    ${CASHBACKS_FRAGMENT}
    ${CASHBACKS_WITHDRAWAL_FRAGMENT}
    ${OFFSET_BASED_PAGE_INFO_FRAGMENT}
`;
