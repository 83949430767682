const PRIVATE_USER_QUERY = `
  query PrivateUserQuery {
    me {
      getUserInfo {
        id
        image {
          id
          url(width: 60, height: 60)
        }
        nickname
        email
        isAdmin
        offerVoteValue
        emailConfirmed
        status
        agreedWithPrivacyPolicy
        createdAt
        totalComments
        bankAccountDetails {
            cpf
            fullName
            pix {
              type
              key
            }
        }
      }
    }
  }
`;

export default PRIVATE_USER_QUERY;
