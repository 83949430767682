import { isIncentiveLoginBannerSideEnabled } from '@/presentation/services/featureFlags';
import LoginSideBar, { LoginSideBarProps } from '../LoginSideBar';

export type LoginSideBarEnhancedProps = {
  isLogged: boolean;
} & LoginSideBarProps;

function LoginSideBarEnhanced({
  componentSourceName,
  googleClientId,
  isLogged,
}: LoginSideBarEnhancedProps) {
  const shouldShowLoginSideBar = isIncentiveLoginBannerSideEnabled();

  if (shouldShowLoginSideBar && !isLogged) {
    return (
      <LoginSideBar
        componentSourceName={componentSourceName}
        googleClientId={googleClientId}
      />
    );
  }

  return null;
}

export default LoginSideBarEnhanced;
