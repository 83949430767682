import { getCommunityOffers$ } from '@/infra/api/offers/helpers/getCommunityOffers';
import { useApiCache } from '../../../../../hooks/useApiCache';

export const useCommunityFeedCache = () => {
  const {
    communityOffers,
    loading: loadingCommunity,
    isPaginationLoading: isPaginationLoadingCommunity,
  } = useApiCache(getCommunityOffers$) || {};

  return {
    edges: communityOffers?.edges,
    loading: loadingCommunity,
    pageInfo: communityOffers?.pageInfo,
    paginationLoading: isPaginationLoadingCommunity,
    offsetBasedPageInfo: communityOffers?.offsetBasedPageInfo,
  };
};
