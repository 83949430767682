const prefix = '@deals';

export const DealsActionType = {
  GetDuplicatedDeals: `${prefix}/GET_DUPLICATED_DEALS`,
  GetDealToEdit: `${prefix}/GET_DEAL_TO_EDIT`,
  GetDeal: `${prefix}/GET_DEAL`,
  GetHottestDeals: `${prefix}/GET_HOTTEST_DEALS`,
  GetRecommendationsHottestDeals: `${prefix}/GET_RECOMMENDATION_HOTTEST_DEALS`,
  GetRecommendationsPublicHottestDeals: `${prefix}/GET_RECOMMENDATION_PUBLIC_HOTTEST_DEALS`,
  PostExpireOffer: `${prefix}/POST_EXPIRE_OFFER`,
  DeleteOffer: `${prefix}/DELETE_OFFER`,
  PostReportOffer: `${prefix}/POST_REPORT_OFFER`,
  PostSendOfferToTelegram: `${prefix}/POST_SEND_OFFER_TO_TELEGRAM`,
  PostUpdateOfferStatus: `${prefix}/POST_UPDATE_OFFER_STATUS`,
  PostUpdateOfferLockedComments: `${prefix}/POST_UPDATE_OFFER_LOCKED_COMMENTS`,
  PostUpdateOfferIsPicked: `${prefix}/POST_UPDATE_OFFER_IS_PICKED`,
  PostUpdateOfferIsTracked: `${prefix}/POST_UPDATE_OFFER_IS_TRACKED`,
  PostUpdateOfferExpireLock: `${prefix}/POST_UPDATE_OFFER_EXPIRE_LOCK`,
  GetLastVisited: `${prefix}/GET_LAST_VISITED`,
  GetDealsVotes: `${prefix}/GET_DEALS_VOTES`,
  PostUpdateLastVisited: `${prefix}/POST_UPDATE_LAST_VISITED`,
  PostDealDetailVisit: `${prefix}/POST_DEAL_DETAIL_VISIT`,
  GetDealTip: `${prefix}/GET_DEAL_TIP`,
};
