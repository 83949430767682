import { getCashbacks } from '@/infra/api/cashback/endpoints';
import { useApiCache } from '@/presentation/hooks/useApiCache';
import { useEffect } from 'react';

export const useUserCashbacks = () => {
  const { cashbacks, loading } = useApiCache(getCashbacks.cache$) || {
    loading: true,
  };

  useEffect(() => {
    getCashbacks.requestAsPromise({ limit: 40 }).catch(() => {});
  }, []);

  return {
    cashbacks,
    loading,
  };
};
