import { NavigationItemParams } from '@/presentation/components/BottomNavigation/component';
import { NavigationIcon } from '@/presentation/components/BottomNavigation/components/NavigationIcon';
import { Anchor } from '@/presentation/components/BottomNavigation/style';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { getWishlistUrl } from '@/presentation/services/urls';

import { Flag } from '@pelando/fontawesome/icons';

import Link from 'next/link';
import { useRef } from 'react';
import { useRouter } from 'next/router';

export function AlertsNavigationItem({
  onClick,
  active,
  count,
}: NavigationItemParams) {
  const { t } = useTranslation('header', 'onboardingContext');
  const { locale } = useRouter();
  const alertRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={alertRef}>
      <Link
        href={getWishlistUrl(locale)}
        passHref
        prefetch={false}
        legacyBehavior
      >
        <Anchor onClick={onClick} aria-current={active}>
          <NavigationIcon icon={Flag} count={count} />
          <span>{t('alerts-link-text')}</span>
        </Anchor>
      </Link>
    </div>
  );
}
