import Link from 'next/link';
import { styled, css } from 'styled-components';
import {
  BodyHeavy,
  BodyLight,
  Button,
  CaptionLight,
  Colors,
  Display,
  Icon,
  Image,
  MediaQuery,
  Theme,
} from '@pelando/components';
import { CashbackStatus, WithdrawalStatus } from '@/domain/entities/Cashback';

export const modalStyle = css`
  padding: 16px 16px 24px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    width: 500px;
    padding: 24px 24px 32px;
  }
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;

  @media ${MediaQuery.SCREEN_MD_UP} {
    padding: 0 24px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  border-bottom: 1px solid rgb(${Theme.colors.Golf});
  margin-bottom: 24px;
`;

export const StoreImage = styled(Image)`
  &&& {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;

    img {
      width: 100%;
      height: 100%;
      aspect-ratio: 1 / 1;
      border-radius: 12px;
      border: 3px solid rgb(${Theme.colors.Brand});
    }
  }
`;

export const StoreName = styled.p`
  ${BodyHeavy}
  margin-bottom: 8px;
`;

export const Value = styled.p`
  ${Display}
  margin-bottom: 8px;
`;

export const PurchaseInfo = styled.p`
  ${BodyLight}
`;

export const History = styled.ol`
  width: 100%;
  margin-bottom: 32px;
  list-style: none;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const HistoryItem = styled.li`
  ${CaptionLight}
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-left: 28px;
  position: relative;
  color: rgb(${Colors.Gray});

  strong {
    ${BodyHeavy}
    color: rgb(${Theme.colors.Alpha});
  }

  i {
    position: absolute;
    left: -30px;
  }

  &:first-child {
    i:first-child::before {
      content: '';
      position: absolute;
      right: 50%;
      transform: translateX(-50%);
      top: 2rem;
      width: 1px;
      height: 4rem;
      margin: auto 0;
      background-color: rgb(${Theme.colors.Golf});
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const GoBackButton = styled(Button)`
  ${BodyHeavy}
  width: 100%;
  max-width: 366px;
  padding: 14px 24px;
`;

export const InfoLink = styled(Link)`
  ${BodyHeavy}
  color: rgb(${Theme.colors.Alpha});
`;

export const PendingIcon = styled(Icon)<{
  status: CashbackStatus | WithdrawalStatus;
}>`
  font-size: 24px;

  ${({ status }) => {
    if (status === CashbackStatus.PENDING)
      return css`
        color: rgb(${Colors.Mustard});
      `;

    if (status === CashbackStatus.APPROVED)
      return css`
        color: rgb(${Colors.Green});
      `;

    if (status === CashbackStatus.DECLINED)
      return css`
        color: rgb(${Colors.Gray});
      `;

    if (status === WithdrawalStatus.PENDING)
      return css`
        color: rgb(${Colors.Mustard});
      `;

    if (status === WithdrawalStatus.SENT)
      return css`
        color: rgb(${Colors.Green});
      `;

    if (status === WithdrawalStatus.DECLINED)
      return css`
        color: rgb(${Colors.Gray});
      `;
    return css`
      color: rgb(${Colors.Green});
    `;
  }};
`;

export const SuccessPurchaseIcon = styled(Icon)`
  font-size: 24px;
  color: rgb(${Colors.Green});
`;

export const ContactButton = styled(Button)`
  ${BodyHeavy}
  width: 100%;
  max-width: 366px;
  padding: 14px 24px;
`;
