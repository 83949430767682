import { useEffect, useState } from 'react';
import { CommunitysOffersCache } from '@/infra/api/offers/types/CommunityOffers';
import { getOnboardingCommunitiesLocalStorage } from '@/infra/storage/onboardingManagement';
import { FeaturedOffer, OfferStatus } from '@/domain/entities/Offer';
import {
  getCommentedOffers,
  getHottestFeedOffers,
  getRecentsOffers,
  getCommunityOffers,
  getRecommendedPublicOffers,
} from '@/infra/api/offers/endpoints';
import { CommentedOffersCache } from '@/infra/api/offers/types/CommentedOffers';
import { HottestOffersCache } from '@/infra/api/offers/types/HottestOffers';
import { RecentsOffersCache } from '@/infra/api/offers/types/RecentOffers';
import {
  DEFAULT_FEED_QUERIES_LIMIT,
  OfferQueryPageInfo,
} from '@/infra/api/offers/types/shared';
import { getRecommendationEndpointRequest } from '@/infra/api/offers/helpers/getRecommendationEndpointRequest';
import { SpecialFeedTab } from '../../../../../services/feedTabsCategories';


type FeaturedOffersProps =
  | {
      edges: FeaturedOffer[];
      pageInfo: OfferQueryPageInfo;
    }
  | undefined;

type UseLoadFeed = {
  isSpecialTab: boolean;
  feedOffers: FeaturedOffersProps;
  initialTab: SpecialFeedTab;
};

const useLoadFeed = ({ isSpecialTab, feedOffers, initialTab }: UseLoadFeed) => {
  const [recombeeQueryFailed, setRecombeeQueryFailed] = useState(false);

  const communityIds = getOnboardingCommunitiesLocalStorage();

  useEffect(() => {
    if (!isSpecialTab) {
      getCommunityOffers.cache$.next({
        loading: false,
        communityOffers: feedOffers,
      } as CommunitysOffersCache);
      return;
    }

    if (
      isSpecialTab &&
      initialTab === SpecialFeedTab.FEATURED &&
      !getRecommendedPublicOffers.cache$.value
    ) {
      getRecommendationEndpointRequest({
        variables: { limit: DEFAULT_FEED_QUERIES_LIMIT },
        onError: () => setRecombeeQueryFailed(true),
      });
    }

    if (
      initialTab === SpecialFeedTab.COMMENTED &&
      !getCommentedOffers.cache$.value
    ) {
      getCommentedOffers.cache$.next({
        loading: false,
        commentedOffers: feedOffers,
      } as CommentedOffersCache);
      return;
    }

    if (
      initialTab === SpecialFeedTab.HOTTEST &&
      !getHottestFeedOffers.cache$.value
    ) {
      if (communityIds?.length) {
        getHottestFeedOffers.requestAsPromise({
          limit: DEFAULT_FEED_QUERIES_LIMIT,
          filters: {
            status: OfferStatus.ACTIVE,
            communityIds,
          },
        });
        return;
      }

      getHottestFeedOffers.cache$.next({
        loading: false,
        hottestOffers: feedOffers,
      } as HottestOffersCache);
      return;
    }

    if (
      initialTab === SpecialFeedTab.RECENT &&
      !getRecentsOffers.cache$.value
    ) {
      getRecentsOffers.cache$.next({
        loading: false,
        recentOffers: feedOffers,
      } as RecentsOffersCache);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedOffers]);

  return { recombeeQueryFailed };
};

export default useLoadFeed;
