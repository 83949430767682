import { Image } from '@pelando/components';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { CircleInfo } from '@pelando/fontawesome/icons';
import { Container, CustomButton, ErrorIcon, Subtitle, Title } from './style';

export enum ModalType {
  SUCCESS,
  ERROR,
}

type CashbackSuccessErrorModal = {
  onCloseModal: () => void;
  title: string;
  subtitle: string;
  type: ModalType;
};

const CashbackSuccessErrorModal = ({
  onCloseModal,
  title,
  subtitle,
  type,
}: CashbackSuccessErrorModal) => {
  const { t } = useTranslation('cashbackBalance');
  return (
    <Container>
      {type === ModalType.SUCCESS ? (
        <Image
          width={120}
          height={120}
          alt="success-icon"
          src="/assets/search/alert-incentive-modal-68x68.gif"
          fetchPriority="high"
        />
      ) : (
        <ErrorIcon icon={CircleInfo} />
      )}
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <CustomButton onClick={onCloseModal}>
        {t('cashback-success-modal-button')}
      </CustomButton>
    </Container>
  );
};

export default CashbackSuccessErrorModal;
