import {
  Button,
  BodyHeavy,
  SmallLight,
  Theme,
  Colors,
  CaptionLight,
  Display,
  MediaQuery,
} from '@pelando/components';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: unset;

  @media ${MediaQuery.SCREEN_MD_UP} {
    padding: 24px;
  }
`;

export const Tip = styled.div`
  width: 100%;
  ${SmallLight}
  display: flex;
  gap: 8px;
  padding: 12px 8px;
  border-radius: 8px;
  background: rgb(${Theme.colors.Kilo});
  margin-bottom: 24px;
  align-items: center;
  text-align: justify;
  margin-top: 24px;

  i {
    font-size: 24px;
  }
`;

export const ConfirmButton = styled(Button)`
  ${BodyHeavy}
  width: 100%;
  max-width: 366px;
  padding: 14px 24px;
`;

export const Price = styled.span`
  ${Display}

  color: rgb(${Theme.colors.Brand});
`;

export const Title = styled.span`
  ${BodyHeavy}

  color: rgb(${Theme.colors.Alpha});
`;

export const Subtitle = styled.span`
  ${CaptionLight}

  color: rgb(${Colors.Gray});
`;

export const Label = styled.label`
  ${CaptionLight}

  color: rgb(${Colors.Gray});
`;

export const Data = styled.label`
  ${CaptionLight}
  text-align: end;
`;

export const PriceContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const DataContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

export const LabelContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  margin-top: 12px;
`;

export const Divisor = styled.span`
  height: 1px;
  width: 100%;
  background-color: rgb(${Theme.colors.Golf});
  margin-top: 22px;
  margin-bottom: 22px;
`;
