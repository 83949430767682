import { LockScrollGlobally } from '@/presentation/styles/global';
import { Icon, useModal } from '@pelando/components';
import { useCallback } from 'react';
import { CircleInfo } from '@pelando/fontawesome/icons';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { mediumScreenCachbackModalStyle } from '@/presentation/styles/shared/modal';
import Translation from '@/presentation/components/Translation';
import { useCurrentUserCache } from '@/presentation/hooks/useCurrentUserCache';
import {
  ConfirmButton,
  Container,
  Price,
  Tip,
  Title,
  Subtitle,
  PriceContent,
  Divisor,
  DataContent,
  LabelContent,
  Label,
  Data,
} from './style';
import CashbackSuccessErrorModal, {
  ModalType,
} from '../CashbackSuccessErrorModal';
import { formatCPF } from '../CashbackForm/validation';

type MockResponse<T> = {
  data: T | null;
  loading: boolean;
  error: string | null;
};

async function mockRedeemRequest<T>(
  simulateError = false
): Promise<MockResponse<T>> {
  return new Promise((resolve) => {
    const response: MockResponse<T> = {
      data: null,
      loading: true,
      error: null,
    };

    setTimeout(() => {
      if (simulateError || Math.random() < 0.3) {
        response.loading = false;
        response.error = 'Something went wrong!';
      } else {
        response.loading = false;
        response.data = { success: true } as T;
      }
      resolve(response);
    }, 500);
  });
}

const CashbackRedeem = ({ balance }: { balance: string }) => {
  const { showModal, closeModal } = useModal();
  const { t } = useTranslation('cashbackBalance');
  const { loggedUser: currentUser } = useCurrentUserCache();

  const handleConfirmRedeem = async () => {
    const { data, error } = await mockRedeemRequest();

    if (error) {
      showModal(
        <>
          <CashbackSuccessErrorModal
            title={t('cashback-error-redeem-modal-title')}
            subtitle={t('cashback-error-redeem-modal-subtitle')}
            type={ModalType.ERROR}
            onCloseModal={closeModal}
          />
          <LockScrollGlobally lock />
        </>
      );

      return;
    }

    if (data) {
      showModal(
        <>
          <CashbackSuccessErrorModal
            title={t('cashback-success-redeem-modal-title')}
            subtitle={t('cashback-success-redeem-modal-subtitle')}
            type={ModalType.SUCCESS}
            onCloseModal={closeModal}
          />
          <LockScrollGlobally lock />
        </>
      );
    }
  };

  return (
    <Container>
      <PriceContent>
        <Title>{t('cashback-redeem-modal-title')}</Title>

        <Price>R$ {balance}</Price>
        <Subtitle>{t('cashback-redeem-modal-subtitle')}</Subtitle>
      </PriceContent>
      <Divisor />
      <DataContent>
        <Title style={{ marginBottom: 8 }}>
          {t('cashback-redeem-modal-data-title')}
        </Title>
        <LabelContent>
          <Label>{t('cashback-redeem-modal-label-name')}</Label>
          <Data>{currentUser?.bankAccountDetails.fullName}</Data>
        </LabelContent>
        <LabelContent>
          <Label>{t('cashback-redeem-modal-label-cpf')}</Label>
          <Data>
            {formatCPF(currentUser?.bankAccountDetails.cpf as string)}
          </Data>
        </LabelContent>
        <LabelContent>
          <Label>{t('cashback-redeem-modal-label-pix')}</Label>
          <Data>{currentUser?.bankAccountDetails.pix?.key}</Data>
        </LabelContent>
      </DataContent>
      <Tip>
        <Icon icon={CircleInfo} />
        <span>
          <Translation translation={t('cashback-redeem-modal-tip')} hasTag>
            O envio do cashback pode demorar até <strong>10 dias úteis</strong>
            para chegar na sua conta.
          </Translation>
        </span>
      </Tip>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <ConfirmButton onClick={handleConfirmRedeem}>
          {t('cashback-redeem-modal-button')}
        </ConfirmButton>
      </div>
    </Container>
  );
};

export const useCashbackRedeemModal = () => {
  const { showModal, closeModal: closeRedeemModal } = useModal();

  const showRedeemModal = useCallback(
    (balance: string) => {
      showModal(
        <>
          <CashbackRedeem balance={balance} />
          <LockScrollGlobally lock />
        </>,
        {
          style: mediumScreenCachbackModalStyle,
        }
      );
    },
    [showModal]
  );

  return {
    showRedeemModal,
    closeRedeemModal,
  };
};

export default CashbackRedeem;
