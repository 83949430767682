export const removeAccents = (text: string) =>
  // convert the text to NFD format. (separate a base char from a special char)
  // then remove chars with accents ('á', 'ô', 'é', etc)
  text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const removeSpecialChars = (text: string): string =>
  removeAccents(text)
    .toLowerCase()
    .replace(/[^\w\s]|_/gm, ' ') // remove symbols ('.', ',', '&', etc)
    .replace(/[ ]{2,}/gm, ' ') // remove not necessary spaces
    .trim();

export const compareWords = (firstWords: string, secondWord: string) =>
  removeSpecialChars(firstWords) === removeSpecialChars(secondWord);

export const capitalizeWord = (str: string) =>
  `${str.charAt(0).toUpperCase()}${str.slice(1).toLocaleLowerCase()}`;

export const removeNotParsedHtmlSymbols = (str: string) =>
  str.replace(/&#?([a-z0-9]){1,};/gi, '');

export const getTitleWords = (str: string) =>
  Array.from(
    new Set(str.replace(/[&/\\#,+()$~%.'":*?<>[\]{}]/g, ' ').split(' '))
  );

export const stripTags = (html: string) => html.replace(/(<([^>]+)>)/gi, '');

export const formatPrice = (price: string) => {
  const finalPrice = parseFloat(price.toString().replace(',', '.'));
  return finalPrice;
};

export const replaceDotsToCommas = (price: string) => {
  const priceNew = price.replace('.', ',');
  return priceNew;
};

export function getCurrencyStringByValue(value: number) {
  return value?.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
}

export function getFormattedPriceByValue(value: number) {
  return getCurrencyStringByValue(value).replace(/^R\$/, '').replace(/\s/, '');
}

export function removeLastWhitespaces(value: string) {
  return value.replace(/\s+$/, '');
}

export function cropString(str: string, num: number) {
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num - 3)}...`;
}
