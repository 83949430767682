import React from 'react';
import { ExploreListItems } from '@pelando/components';
import {
  BaseCommunities,
  EXPLORE_COMMUNITIES,
} from '@/presentation/services/exploreCommunities';
import { useRouter } from 'next/router';
import { BaseCategories } from '../../services/exploreCategories';

const getExploreItems = (
  exploreCommunitiesOrCategories: BaseCategories | BaseCommunities,
  locale: string,
  onClickItem?: () => void
) =>
  Object.entries(exploreCommunitiesOrCategories)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, { title, slug }]) => title?.[locale] || slug?.[locale])
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .map(([_, { icon, title, slug }]) => ({
      icon,
      text: title?.[locale] || '',
      url: `/${slug?.[locale] || ''}`,
      onClick: () => {
        if (onClickItem) {
          onClickItem();
        }
      },
    }));

type ExploreListItemsEnhancedProps = {
  closeMenu?: () => void;
  className?: string;
};

function ExploreListItemsEnhanced({
  closeMenu,
  className,
}: ExploreListItemsEnhancedProps) {
  const { locale } = useRouter();

  const exploreItems = getExploreItems(
    EXPLORE_COMMUNITIES,
    locale || '',
    closeMenu
  );
  return (
    <ExploreListItems
      exploreItems={exploreItems}
      containerClassName={className}
    />
  );
}

export default ExploreListItemsEnhanced;
