import { ContentWithOverlay, useClickOutside } from '@pelando/components';
import { CircleHeart } from '@pelando/fontawesome/icons';
import { EventCategory, CommunityEvents } from '@/domain/events/analytics';
import { emitTrackingEvent } from '@/infra/analytics/emitTrackingEvent';
import { useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { ExploreIcon } from '../HeaderRightCornerContent/styles';
import { HeaderCommunitiesItemContainer } from './style';

const ExploreListItemsEnhanced = dynamic(
  () => import('../../../ExploreListItems').then((mod) => mod.default),
  { ssr: true }
);

export const HeaderCommunitiesItem = () => {
  const [openCommunities, setOpenCommunities] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonCommunityRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation('header');

  const buttonText = 'communities-link-text';

  const iconName = CircleHeart;

  const handleOpenCommunityOrCommunitiesList = () => {
    setOpenCommunities(true);

    emitTrackingEvent({
      category: EventCategory.Community,
      name: CommunityEvents.ClickToGoBackToCommunities,
      extra: undefined,
    });
  };

  const handleCloseCommunitiesList = () => {
    setOpenCommunities(false);
  };

  useClickOutside([containerRef], handleCloseCommunitiesList);

  return (
    <>
      <div ref={buttonCommunityRef}>
        <HeaderCommunitiesItemContainer
          onClick={handleOpenCommunityOrCommunitiesList}
          key={1}
          icon={<ExploreIcon icon={iconName} />}
          text={t(buttonText)}
        />
      </div>

      <ContentWithOverlay open={openCommunities}>
        <div ref={containerRef}>
          <ExploreListItemsEnhanced closeMenu={handleCloseCommunitiesList} />
        </div>
      </ContentWithOverlay>
    </>
  );
};
