import {
  BodyHeavy,
  BodyLight,
  Button,
  CaptionHeavy,
  CaptionLight,
  Colors,
  FontWeight,
  H2Heavy,
  H3Heavy,
  MediaQuery,
  SmallLight,
  TextInput,
  Theme,
} from '@pelando/components';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  height: 90%;

  margin-top: 32px;

  button {
    margin-top: auto;
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    align-items: center;
    height: unset;
    margin-top: unset;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  justify-content: center;
  gap: 4px;

  picture {
    display: none;
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    text-align: center;
    align-items: center;
    picture {
      display: block;
    }
  }
`;

export const Title = styled.div`
  ${H3Heavy};

  @media ${MediaQuery.SCREEN_MD_UP} {
    ${H2Heavy};
  }
`;

export const Subtitle = styled.div`
  ${CaptionLight};
  color: rgb(${Colors.Gray});
  max-width: 300px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    ${BodyLight};
    max-width: unset;
  }
`;

export const FormTextInput = styled(TextInput)`
  & {
    width: 100%;
  }

  input {
    &:disabled {
      color: gray;
    }
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    min-width: 454px;
  }
`;

export const FormLabel = styled.div`
  display: flex;
  justify-content: start;
  ${CaptionHeavy}
  font-weight: ${FontWeight.BOLD};
  margin-bottom: 8px;
  margin-top: 12px;
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  margin-top: 12px;

  div {
    width: 100%;
  }
`;

export const Tip = styled.div`
  width: 100%;
  ${SmallLight}
  display: flex;
  gap: 8px;
  padding: 12px 8px;
  border-radius: 8px;
  background: rgb(${Theme.colors.Kilo});
  margin-bottom: 24px;
  align-items: center;
  text-align: justify;
  margin-top: 24px;

  i {
    font-size: 24px;
  }
`;

export const ConfirmButton = styled(Button)`
  ${BodyHeavy}
  width: 100%;
  max-width: 366px;
  padding: 14px 24px;
`;
