import { useEffect, useState } from 'react';

import { getLoggedUser } from '@/infra/api/auth/endpoints';
import { useApiCache } from '@/presentation/hooks/useApiCache';
import { isOnboardingIncentiveAppEnabled } from '@/presentation/services/featureFlags';
import { Xmark } from '@pelando/fontawesome/icons';
import { useModal } from '@pelando/components';
import { removeOnboardingStageFromLocalStorage } from '@/infra/storage/onboardingManagement';

import { useTranslation } from '@/presentation/hooks/useTranslation';
import {
  SpecialFeedTabSlugPtBR,
  SpecialFeedTabSlugEnUS,
} from '@/presentation/services/feedTabsCategories';
import { useRouter } from 'next/router';
import {
  StepTypes,
  emitOnboardingCloseView,
  emitOnboardingStartEvent,
} from '../consts';
import {
  CloseButtonIcon,
  MobileProgressBar,
  MobileProgressBarContainer,
  StepsModalContainer,
  StepsModalProgressBar,
} from './style';

import VotingStepNew from '../VotingStepNew';
import CommunityStepIcon from '../CategoryStepIcon/communitiesStep';
import IncentiveStep from '../AppIncentiveTypeAStep';

type OnboardingModalProps = {
  onFinished: () => void;
  stepTypeDefault?: StepTypes;
};

export function OnboardingModal({
  onFinished,
  stepTypeDefault,
}: OnboardingModalProps) {
  const { pathname, locale } = useRouter();
  const onboardingIncentiveAppEnabled = isOnboardingIncentiveAppEnabled();

  const onboardingModalSteps = [
    StepTypes.INCENTIVE_STEP,
    StepTypes.VOTING_STEP,
    StepTypes.CATEGORIES_STEP,
  ];

  const { closeModal } = useModal();

  const { loggedUser } = useApiCache(getLoggedUser.cache$) || {};

  const { t } = useTranslation('onboarding');

  const redirectFeed =
    locale === 'pt-BR'
      ? SpecialFeedTabSlugPtBR.HOTTEST
      : SpecialFeedTabSlugEnUS.HOTTEST;

  const stepIndexDefault = onboardingModalSteps.findIndex(
    (step) => step === stepTypeDefault
  );

  const initialIndex = onboardingIncentiveAppEnabled ? 0 : 1;

  const [currentStep, setCurrentStep] = useState<StepTypes>(
    onboardingModalSteps[stepIndexDefault > 0 ? stepIndexDefault : initialIndex]
  );

  const numberOfSteps = onboardingModalSteps.length;
  const currentStepIndex = onboardingModalSteps.findIndex(
    (step) => step === currentStep
  );

  useEffect(() => {
    emitOnboardingStartEvent(loggedUser?.id || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClickToContinue = () => {
    if (currentStepIndex < numberOfSteps - 1) {
      const nextStep = onboardingModalSteps[currentStepIndex + 1];
      setCurrentStep(nextStep);
    } else {
      if (pathname === '/[slug]') {
        window.location.assign(redirectFeed);
      }
      onFinished();
    }
  };

  const handleOnboardingClose = () => {
    if (pathname === '/[slug]') {
      window.location.assign(redirectFeed);
    }
    emitOnboardingCloseView(loggedUser?.id || '', currentStep);
    removeOnboardingStageFromLocalStorage();

    closeModal();
  };

  const StepsContents: {
    [index in StepTypes]: JSX.Element;
  } = {
    INCENTIVE_STEP: <IncentiveStep onClickContinue={handleOnClickToContinue} />,
    VOTING_STEP: (
      <VotingStepNew
        onClickContinue={handleOnClickToContinue}
        loggedUserId={loggedUser?.id || ''}
      />
    ),

    CATEGORIES_STEP: (
      <CommunityStepIcon
        onClickContinue={handleOnClickToContinue}
        loggedUserId={loggedUser?.id || ''}
        onFinished={onFinished}
      />
    ),
  };

  const progressBarPercent = ((currentStepIndex + 1) / 4) * 100;

  const isIncentiveStep = currentStep === StepTypes.INCENTIVE_STEP;

  return (
    <StepsModalContainer>
      <CloseButtonIcon
        icon={Xmark}
        aria-label={t('onboarding-modal-close-button-aria-label')}
        role="button"
        onClick={
          isIncentiveStep ? handleOnClickToContinue : handleOnboardingClose
        }
      />
      {!isIncentiveStep && (
        <MobileProgressBarContainer>
          <MobileProgressBar percent={progressBarPercent} />
        </MobileProgressBarContainer>
      )}

      {StepsContents[currentStep]}
      <StepsModalProgressBar
        currentStepIndex={currentStepIndex}
        numberOfSteps={numberOfSteps}
      />
    </StepsModalContainer>
  );
}
