import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { useClickOutside } from '@pelando/components';
import { Header as HeaderComponent } from '@/presentation/components/Header';
import { Footer as FooterComponent } from '@/presentation/modules/Footer';
import ExploreListItemsMenu from '@/presentation/components/ExploreListItemsMenu';
import dynamic from 'next/dynamic';
import env from '@/config/environment';
import { withPrivacyPolicyHelper } from '@/infra/api/auth/helpers/getWithPrivacyPolicy';
import { getLoggedUserHelper } from '@/infra/api/auth/helpers/getLoggedUser';
import { setupWebpushHandlers } from '@/infra/notifications';
import { getCashbackAvailableBalance } from '@/infra/api/cashback/endpoints';
import { useAuthGuardModal } from '../hooks/useAuthGuardModal';
import { getThemeName, setTheme, startThemeListening } from '../services/theme';
import { DEFAULT_STATE, LayoutContext, useLayoutContext } from './context';
import useEnhancedUnconfirmedEmailGuardModal from '../hooks/useUnconfirmedEmailGuardModal';
import { useAgreeWithPrivacyPolicyModal } from '../hooks/useAgreeWithPrivacyPolicyModal';
import { useMouseMove } from '../hooks/useMouseMove';
import {
  isFirebaseWebPushEnabled,
  isShowAppInstallIncentivesEnabled,
} from '../services/featureFlags';
import { useCurrentUserCache } from '../hooks/useCurrentUserCache';
import { LayoutContainer, MainContainer, FixedBottomNavigation } from './style';
import { useShowAccessToastFeed } from '../hooks/useShowAccessToastFeed';
import { useOnboardingModal } from '../hooks/useNewOnboardingModal';

const EnhancedCookieToast = dynamic(
  () =>
    import('../components/CookieAcceptConfigToast').then((mod) => mod.default),
  { ssr: false }
);

const AppAccessToastFeed = dynamic(
  () =>
    import('../pages/public/feed/components/AppAccessToastFeed').then(
      (mod) => mod.default
    ),
  { ssr: false }
);

type LayoutProps = PropsWithChildren<object>;

function Layout({ children }: LayoutProps) {
  const { asPath, locale } = useRouter();
  const { loggedUser: currentUser, isLogged } = useCurrentUserCache();
  const [isCookieToastVisible, setCookieToastVisible] =
    useState<boolean>(false);

  const googleClientId = env.AUTH_GOOGLE_APP_ID;

  useAuthGuardModal({ googleClientId });
  useEnhancedUnconfirmedEmailGuardModal();

  useMouseMove(() => setCookieToastVisible(true));

  useEffect(() => {
    startThemeListening(locale);
  }, [locale]);

  const handleagreeWithLastPrivacyTerms = withPrivacyPolicyHelper();

  useAgreeWithPrivacyPolicyModal({
    isLogged,
    route: asPath,
    agreedWithPrivacyPolicy: !!currentUser?.agreedWithPrivacyPolicy,
    createdAt: currentUser?.createdAt || '',
    agreeWithLastPrivacyTerms: handleagreeWithLastPrivacyTerms,
  });

  useOnboardingModal({ isLogged });

  useEffect(() => {
    setTheme(getThemeName(locale));
  }, [isLogged, locale]);

  useEffect(() => {
    getLoggedUserHelper();
  }, []);

  useEffect(() => {
    getCashbackAvailableBalance.requestAsPromise();
  }, []);

  useEffect(() => {
    if (isLogged && !window.__FIREBASE_MSG__ && isFirebaseWebPushEnabled()) {
      // eslint-disable-next-line no-console
      console.log('[WebPush] Starting to listen to webpush notifications');
      setupWebpushHandlers();
    }
  }, [isLogged, currentUser?.id]);

  const showAppInstallIncentives = isShowAppInstallIncentivesEnabled();

  const { isShowToast, handleToastClose } = useShowAccessToastFeed({
    showAppInstallIncentives,
  });

  const value = useMemo(
    () => ({ ...DEFAULT_STATE, showingToast: isShowToast, googleClientId }),
    [isShowToast, googleClientId]
  );

  return (
    <LayoutContext.Provider value={value}>
      <LayoutContainer>
        {isShowToast && <AppAccessToastFeed onClose={handleToastClose} />}
        {children}
        {isCookieToastVisible && <EnhancedCookieToast />}
      </LayoutContainer>
    </LayoutContext.Provider>
  );
}

function Header() {
  const { googleClientId, showingToast } = useLayoutContext();

  return (
    <HeaderComponent hasBanner={showingToast} googleClientId={googleClientId} />
  );
}

function Main({ children }: PropsWithChildren<Record<string, unknown>>) {
  const { showingToast } = useLayoutContext();

  return (
    <MainContainer data-hasappbanner={showingToast}>{children}</MainContainer>
  );
}

function Footer({ removeMargin }: { removeMargin?: boolean }) {
  return <FooterComponent removeMargin={removeMargin} />;
}

function BottomNavigation() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const exploreMenuRef = useRef<HTMLDivElement>(null);
  const bottomNavigationRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  useClickOutside([exploreMenuRef, bottomNavigationRef], closeMenu);

  return (
    <>
      {isOpen ? (
        <ExploreListItemsMenu closeMenu={closeMenu} menuRef={exploreMenuRef} />
      ) : undefined}
      <FixedBottomNavigation
        categoriesOpen={isOpen}
        closeMenu={closeMenu}
        onClickMenu={toggleMenu}
        innerRef={bottomNavigationRef}
      />
    </>
  );
}

export default {
  Root: Layout,
  Header,
  Main,
  Footer,
  BottomNavigation,
};
