import { useModal } from '@pelando/components';
import { useEffect } from 'react';
import {
  addTooltipOnboardingAlertHomeLocalStorage,
  getOnboardingStageLocalStorage,
  removeOnboardingStageFromLocalStorage,
} from '@/infra/storage/onboardingManagement';
import { StepTypes } from '@/presentation/components/NewOnboarding/consts';
import {
  addOnboardingContextAlertOtherFunctionStageLocalStorage,
  addOnboardingContextCheckVideo,
} from '@/infra/storage/onboardContextManagement';
import { addContextOnboardingVideoAlertLocalStorage } from '@/infra/storage/contextOnboardingVideoAlert';
import { addOnboardingContextSearchStageLocalStorage } from '@/infra/storage/onboardingContextSearch';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { DealOnboardingModal } from '@/presentation/components/DealOnboarding/DealOnboardingModal';
import {
  getDealOnboardingStageLocalStorage,
  removeDealOnboardingStageFromLocalStorage,
} from '@/infra/storage/dealOnboardingStorage';
import { DealStepTypes } from '@/presentation/components/DealOnboarding/consts';
import { LockScrollGlobally } from '@/presentation/styles/global';
import { onboardingDealModalStyle, onboardingModalStyle } from './style';
import {
  isOnboardDealsEnabled,
  isOnboardingUserTypeEnabled,
} from '../../services/featureFlags';
import { useCashbackPageStatus } from '../useDealCashbackStatus';

const OnboardingModal = dynamic(
  () =>
    import('@/presentation/components/NewOnboarding/OnboardingModal').then(
      (mod) => mod.OnboardingModal
    ),
  { ssr: false }
);

type OnboardingModalProps = {
  isLogged?: boolean;
};

export function useOnboardingModal({ isLogged }: OnboardingModalProps) {
  const { showModal, closeModal } = useModal();
  const { pathname } = useRouter();
  const { hasCashback } = useCashbackPageStatus();

  const storagedOnboarding = getOnboardingStageLocalStorage();
  const storagedDealOnboarding = getDealOnboardingStageLocalStorage();

  const isShowOnboardingModal =
    !!storagedOnboarding &&
    isLogged &&
    isOnboardingUserTypeEnabled() &&
    !hasCashback;

  const isDealPage = pathname.includes('/d');

  const isOnboardingDeal =
    !!storagedDealOnboarding &&
    isLogged &&
    isDealPage &&
    isOnboardDealsEnabled() &&
    !hasCashback;

  useEffect(
    () => {
      if (isOnboardingDeal) {
        showModal(
          <>
            <DealOnboardingModal
              onFinished={() => {
                removeDealOnboardingStageFromLocalStorage();
                removeOnboardingStageFromLocalStorage();
                closeModal();
              }}
              stepTypeDefault={storagedDealOnboarding as DealStepTypes}
            />
            <LockScrollGlobally lock />
          </>,
          {
            style: onboardingDealModalStyle,
            modalBoxBorderRadius: 0,
            bannerImage: false,
            preventClose: true,
          }
        );
      }
      if (!storagedDealOnboarding && isShowOnboardingModal) {
        showModal(
          <OnboardingModal
            onFinished={() => {
              removeOnboardingStageFromLocalStorage();
              closeModal();
              addContextOnboardingVideoAlertLocalStorage();
              addTooltipOnboardingAlertHomeLocalStorage(true);
              addOnboardingContextAlertOtherFunctionStageLocalStorage();
              addOnboardingContextSearchStageLocalStorage();
              addOnboardingContextCheckVideo();
            }}
            stepTypeDefault={storagedOnboarding as StepTypes}
          />,
          {
            style: onboardingModalStyle,
            modalBoxBorderRadius: 0,
            bannerImage: false,
            preventClose: true,
          }
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLogged]
  );
}
