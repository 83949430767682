import React, { ReactNode, useEffect, useState } from 'react';
import { SwitchToggle } from '@pelando/components';
import { LoggedUser } from '@/domain/entities/User';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { isNewFeedPaginationEnabled } from '@/presentation/services/featureFlags';
import {
  ExploreCommunities,
  getExploreCommunitiesItemsSlug,
  getExploreCommunitiesItemsTitleBySlug,
} from '@/presentation/services/exploreCommunities';
import { useRouter } from 'next/router';
import { Langs } from '@/locales';
import { VoteVariables } from '@/infra/api/votes/types/Vote';
import { RemoveVoteVariables } from '@/infra/api/votes/types/RemoveVote';
import { FilterOptions, OfferSortOption } from '@/domain/entities/Filter';
import { Container, SwitchContainer, SwitchText, TabOptions } from './style';
import { ChangeFilterOptions, DealsListContent } from '../..';
import getExploreTabBySortOption from '../../services/getExploreTabBySortOption';
import getSortOptionByExploreTab from '../../services/getSortOptionByExploreTab';
import FeedContent from '../../../../../components/Feed/FeedContent';
import { OrderByTabs } from '../../../../../components/Feed/OrderByTabs';
import { SwitchSkeleton, SwitchTextSkeleton } from '../SpecialFeedTabs/style';

export enum ExploreTab {
  RECENT,
  COMMENTED,
  HOTTEST,
}

export const ExploreTabByLocale = {
  [ExploreTab.RECENT]: 'tab-link-recents',
  [ExploreTab.COMMENTED]: 'tab-link-comments',
  [ExploreTab.HOTTEST]: 'tab-link-hottest',
};

type ExploreFilter = Pick<
  FilterOptions,
  'hideExpired' | 'sortBy' | 'hottestFeedPeriod'
>;

type ExploreFeedTabsProps = {
  onChangeFilter: (
    filter: ChangeFilterOptions,
    exploreTab?: ExploreTab
  ) => void;
  refetchFilterContent: (filter: ChangeFilterOptions) => void;
  initialFilters: ExploreFilter;
  exploreContent?: DealsListContent;
  categorySlug: string;
  onVote: (variables: VoteVariables) => void;
  onRemoveVote: (variables: RemoveVoteVariables) => void;
  insertContentAt?: { [at: number]: ReactNode };
  loading?: boolean;
  currentUser?: LoggedUser;
  isCommunityFeed?: boolean;
  initialPage?: number;
  setIsShowingPagination?: (isShowing: boolean) => void;
  googleClientId?: string;
};

export default function ExploreFeedTabs({
  initialFilters,
  onChangeFilter,
  refetchFilterContent,
  exploreContent,
  categorySlug,
  onVote,
  onRemoveVote,
  insertContentAt,
  loading,
  currentUser,
  isCommunityFeed,
  initialPage,
  setIsShowingPagination,
  googleClientId,
}: ExploreFeedTabsProps) {
  const { replace, query, locale } = useRouter();

  const { t } = useTranslation('feed');
  const { hideExpired, sortBy, hottestFeedPeriod } = initialFilters || {};

  const [currentTab, setTab] = useState<ExploreTab>(
    getExploreTabBySortOption(sortBy as OfferSortOption) || ExploreTab.RECENT
  );

  const categoryName = getExploreCommunitiesItemsTitleBySlug(
    categorySlug as ExploreCommunities,
    locale || ''
  );

  const slug = getExploreCommunitiesItemsSlug(
    categorySlug as ExploreCommunities,
    locale as Langs
  );

  const isFeedPaginationEnabled = isNewFeedPaginationEnabled();

  const removePageQueryParam = () => {
    delete query.page;
    replace({ query }, undefined, { shallow: true });
  };

  const updateTab = (tab: ExploreTab) => {
    onChangeFilter({ sortBy: getSortOptionByExploreTab(tab) }, tab);
    setTab(tab);
    removePageQueryParam();
  };

  useEffect(() => {
    const explorerTabSortOption = getExploreTabBySortOption(
      sortBy as OfferSortOption
    );
    if (explorerTabSortOption !== ExploreTab.RECENT) {
      refetchFilterContent({
        sortBy: getSortOptionByExploreTab(explorerTabSortOption),
      });
      setTab(explorerTabSortOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  const hasPagination =
    currentTab === ExploreTab.RECENT && isFeedPaginationEnabled;

  return (
    <Container>
      <TabOptions data-is-community-feed={isCommunityFeed}>
        <OrderByTabs
          activeOrder={getSortOptionByExploreTab(currentTab)}
          onChangeOrder={(order) => updateTab(getExploreTabBySortOption(order))}
          loading={loading}
          categorySlug={categorySlug}
          onChangeFilter={onChangeFilter}
          currentHottestPeriod={hottestFeedPeriod}
        />
        <SwitchContainer>
          {loading ? (
            <>
              <SwitchTextSkeleton animate />
              <SwitchSkeleton animate />
            </>
          ) : (
            <>
              <SwitchText>{t('expireds-filter')}</SwitchText>
              <SwitchToggle
                onChange={() => onChangeFilter({ hideExpired: !hideExpired })}
                checked={hideExpired}
              />
            </>
          )}
        </SwitchContainer>
      </TabOptions>
      {exploreContent && (
        <FeedContent
          key={categorySlug + currentTab}
          content={exploreContent}
          categorySlug={slug || ''}
          categoryName={categoryName || ''}
          onVote={onVote}
          onRemoveVote={onRemoveVote}
          insertContentAt={insertContentAt}
          currentUser={currentUser}
          hasPagination={hasPagination}
          initialPage={initialPage}
          setIsShowingPagination={setIsShowingPagination}
          googleClientId={googleClientId}
        />
      )}
    </Container>
  );
}
