import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';

import { useModal } from '@pelando/components';
import {
  addOnboardingStageLocalStorage,
  addTooltipOnboardingAlertHomeLocalStorage,
  removeOnboardingStageFromLocalStorage,
} from '@/infra/storage/onboardingManagement';
import { addOnboardingContextCheckVideo } from '@/infra/storage/onboardContextManagement';
import { addContextOnboardingVideoAlertLocalStorage } from '@/infra/storage/contextOnboardingVideoAlert';
import {
  clearUnloggedId,
  getDeviceFromUserAgent,
} from '@/infra/api/core/tracker';
import { getDealsAndCouponsAsPath } from '@/presentation/services/regex';
import {
  addDealOnboardingStageLocalStorage,
  removeDealOnboardingStageFromLocalStorage,
} from '@/infra/storage/dealOnboardingStorage';
import { LockScrollGlobally } from '@/presentation/styles/global';
import { useCashbackPageStatus } from '@/presentation/hooks/useDealCashbackStatus';
import { useSocialAuthToastError } from '@/presentation/hooks/useSocialAuthToastError';
import Login from './components/Login';
import Recovery from './components/ResetPassword';
import useSocialPopup, { SocialPopupUrls } from './socialPopup';
import {
  AppleLoginData,
  AuthKind,
  AuthModalState,
  AuthProvider,
  AuthProviderCodeResponse,
  FacebookLoginData,
  GoogleLoginData,
  LoginComponentSourceName,
} from './types';
import { getMetadataClient } from '../../services/runtime';
import { SignupComponentSource } from '../Header';
import env from '../../../config/environment';
import { getLoggedUserHelper } from '../../../infra/api/auth/helpers/getLoggedUser';
import { loginHelper } from '../../../infra/api/auth/helpers/postLogin';
import {
  setAuthTokenToLocalStorage,
  setUserIsProbablyLoggedCookie,
} from '../../../infra/auth/tokenManagement';
import {
  getAuthProviders,
  requestPasswordReset,
  signUp,
  updateEmail,
  updateSignUpMetadata,
} from '../../../infra/api/auth/endpoints';
import {
  onboardingModalStyle as newOnboardingModalStyle,
  onboardingDealModalStyle,
} from '../../hooks/useNewOnboardingModal/style';
import { OnboardingModal } from '../NewOnboarding/OnboardingModal';
import {
  isOnboardDealsEnabled,
  isOnboardingIncentiveAppEnabled,
  isOnboardingUserTypeEnabled,
} from '../../services/featureFlags';
import { StepTypes } from '../NewOnboarding/consts';
import { DealOnboardingModal } from '../DealOnboarding/DealOnboardingModal';
import { DealStepTypes } from '../DealOnboarding/consts';

type SignupMetadataVariables = {
  signupMethod?: AuthProvider;
  componentSource: SignupComponentSource;
};

export type AuthModalProps = {
  defaultProvider?: AuthProvider;
  kind: AuthKind;
  modalTitle?: ReactNode;
  modalSubtitle?: ReactNode;
  onCloseModal: () => void;
  onFinishSuccessfully?: () => void;
  isLogged?: boolean;
  componentSourceName?: LoginComponentSourceName;
  googleClientId?: string;
};

export type SocialAuthResponseData = {
  type: AuthProviderCodeResponse;
  data: FacebookLoginData | GoogleLoginData | AppleLoginData;
  authActionResult: AuthKind.LOGIN | AuthKind.SIGNUP;
};

function AuthModal({
  kind,
  modalTitle,
  modalSubtitle,
  onFinishSuccessfully,
  onCloseModal,
  isLogged,
  defaultProvider,
  componentSourceName,
  googleClientId,
}: AuthModalProps) {
  const [state, setState] = useState<AuthModalState>({ authKind: kind });
  const { showModal, closeModal } = useModal();
  const { pathname, asPath } = useRouter();
  const { hasCashback } = useCashbackPageStatus();
  const hasFinishedRef = useRef(false);
  const { showSocialAuthToastError } = useSocialAuthToastError();

  const onboardingIncentiveAppEnabled = isOnboardingIncentiveAppEnabled();
  const flagOnboardingDefault = isOnboardingUserTypeEnabled();
  const flagOnboardingDeals = isOnboardDealsEnabled();

  const initialStep = StepTypes.INCENTIVE_STEP;

  const initiaDeallStep = onboardingIncentiveAppEnabled
    ? DealStepTypes.INCENTIVE_STEP
    : DealStepTypes.WELCOME_STEP;

  const isDealPage = pathname.includes('/d');

  const isOnboardingDeal = isDealPage && flagOnboardingDeals;

  const { userAgent } = navigator;

  const handleGetAuthProviders = async (email: string) => {
    const response = await getAuthProviders.requestAsPromise({ email });
    return response.data;
  };

  const handleRequestPasswordReset = async (email: string) => {
    const response = await requestPasswordReset.requestAsPromise({
      input: { email },
    });
    return response.data || false;
  };

  const handleUpdateEmail = async (email: string) => {
    await updateEmail.requestAsPromise({ input: { email } });
  };

  const updateMetadata = useCallback(
    ({ componentSource, signupMethod }: SignupMetadataVariables) => {
      const input = JSON.stringify({
        signup_method: signupMethod,
        component_source: componentSource,
        signup_path: pathname,
        client: getMetadataClient(),
      });

      updateSignUpMetadata.requestAsPromise({ input });
    },
    [pathname]
  );

  const facebookAuthPromise = useRef<Promise<FacebookLoginData>>();
  const facebookAuthResolve = useRef<(data: FacebookLoginData) => void>(
    () => {}
  );
  const googleAuthPromise = useRef<Promise<GoogleLoginData>>();
  const googleAuthResolve = useRef<(data: GoogleLoginData) => void>(() => {});

  const appleAuthPromise = useRef<Promise<AppleLoginData>>();
  const appleAuthResolve = useRef<(data: AppleLoginData) => void>(() => {});

  const { openSocialPopup, closeSocialPopup } = useSocialPopup({
    width: 520,
    height: 520,
  });

  const handleFacebookAuth = (authKind: AuthKind) => {
    facebookAuthPromise.current = new Promise((resolve) => {
      facebookAuthResolve.current = resolve;
    });

    openSocialPopup(
      SocialPopupUrls.FACEBOOK,
      authKind,
      componentSourceName,
      asPath
    );

    return facebookAuthPromise.current;
  };

  const handleGoogleAuth = (authKind: AuthKind) => {
    googleAuthPromise.current = new Promise((resolve) => {
      googleAuthResolve.current = resolve;
    });

    openSocialPopup(
      SocialPopupUrls.GOOGLE,
      authKind,
      componentSourceName,
      asPath
    );
    return googleAuthPromise.current;
  };

  const handleAppleAuth = (authKind: AuthKind) => {
    appleAuthPromise.current = new Promise((resolve) => {
      appleAuthResolve.current = resolve;
    });

    openSocialPopup(
      SocialPopupUrls.APPLE,
      authKind,
      componentSourceName,
      asPath
    );

    return appleAuthPromise.current;
  };

  function runOnFinishOnce() {
    if (hasFinishedRef.current) return;

    onFinishSuccessfully?.();
    hasFinishedRef.current = true;
  }

  const handleMessage = async (event: MessageEvent) => {
    if (
      event.origin !== env.PUBLIC_REST_API_URL &&
      event.origin !== env.PUBLIC_API_SUBDOMAIN
    ) {
      return;
    }

    if (!event.data) {
      return;
    }

    let parsedData: SocialAuthResponseData;

    try {
      parsedData = JSON.parse(event.data as string) as SocialAuthResponseData;
    } catch {
      showSocialAuthToastError();
      return;
    }

    const { type, data, authActionResult } = parsedData;

    if (!authActionResult) {
      showSocialAuthToastError();
      return;
    }

    if (authActionResult === AuthKind.SIGNUP && isLogged) {
      // TODO: [sc-23767] : chore refactor onboarding functions
      if (flagOnboardingDeals && !hasCashback) {
        showModal(
          <>
            <DealOnboardingModal
              onFinished={() => {
                addTooltipOnboardingAlertHomeLocalStorage(true);
                removeDealOnboardingStageFromLocalStorage();
                removeOnboardingStageFromLocalStorage();
                closeModal();
              }}
            />
            <LockScrollGlobally lock />
          </>,
          {
            style: onboardingDealModalStyle,
            modalBoxBorderRadius: 0,
            bannerImage: false,
            preventClose: true,
          }
        );
        return;
      }
      if (flagOnboardingDefault && !hasCashback) {
        showModal(
          <OnboardingModal
            onFinished={() => {
              removeOnboardingStageFromLocalStorage();
              closeModal();
              addContextOnboardingVideoAlertLocalStorage();
              addTooltipOnboardingAlertHomeLocalStorage(true);
              addOnboardingContextCheckVideo();
              addOnboardingStageLocalStorage(initialStep);
            }}
          />,
          {
            style: newOnboardingModalStyle,
            modalBoxBorderRadius: 0,
            bannerImage: false,
            preventClose: true,
          }
        );

        return;
      }
    }

    if (type === AuthProviderCodeResponse.FACEBOOK) {
      updateMetadata({
        signupMethod: AuthProvider.FACEBOOK,
        componentSource: SignupComponentSource.UnloggedDefault,
      });
      facebookAuthResolve.current(data as FacebookLoginData);
    }

    if (type === AuthProviderCodeResponse.GOOGLE) {
      updateMetadata({
        signupMethod: AuthProvider.GOOGLE,
        componentSource: SignupComponentSource.UnloggedDefault,
      });
      googleAuthResolve.current(data);
    }

    if (type === AuthProviderCodeResponse.APPLE) {
      updateMetadata({
        signupMethod: AuthProvider.APPLE,
        componentSource: SignupComponentSource.UnloggedDefault,
      });
      appleAuthResolve.current(data);
    }
    setUserIsProbablyLoggedCookie(true);
    getLoggedUserHelper();
    closeSocialPopup();
    runOnFinishOnce();

    if (authActionResult === AuthKind.SIGNUP) {
      if (flagOnboardingDeals && !hasCashback) {
        showModal(
          <>
            <DealOnboardingModal
              onFinished={() => {
                addTooltipOnboardingAlertHomeLocalStorage(true);
                removeDealOnboardingStageFromLocalStorage();
                removeOnboardingStageFromLocalStorage();
                closeModal();
              }}
            />
            <LockScrollGlobally lock />
          </>,
          {
            style: onboardingDealModalStyle,
            modalBoxBorderRadius: 0,
            bannerImage: false,
            preventClose: true,
          }
        );
        return;
      }
      if (flagOnboardingDefault && !hasCashback) {
        showModal(
          <OnboardingModal
            onFinished={() => {
              removeOnboardingStageFromLocalStorage();
              closeModal();
              addContextOnboardingVideoAlertLocalStorage();
              addTooltipOnboardingAlertHomeLocalStorage(true);
            }}
          />,
          {
            style: newOnboardingModalStyle,
            modalBoxBorderRadius: 0,
            bannerImage: false,
            preventClose: true,
          }
        );
      }
    } else {
      closeModal();
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  });

  const handleBasicLogin = async (email: string, password: string) => {
    await loginHelper({
      variables: { input: { email, password } },
      metadata: {
        componentSourceName,
        path: asPath,
        clientDevice: getDeviceFromUserAgent(userAgent),
        clientUserAgent: userAgent,
      },
    });
  };

  const handleBasicSignup = async (email: string, password: string) => {
    await signUp
      .requestAsPromise(
        { input: { email, password } },
        {
          componentSourceName,
          path: asPath,
          groupedPath: getDealsAndCouponsAsPath(asPath),
          clientDevice: getDeviceFromUserAgent(userAgent),
          clientUserAgent: userAgent,
        }
      )
      .then(({ data, error }) => {
        if (error) return;

        if (isOnboardingDeal && !hasCashback) {
          addDealOnboardingStageLocalStorage(initiaDeallStep);
        } else if (flagOnboardingDefault && !hasCashback) {
          addOnboardingStageLocalStorage(initialStep);
        }

        setAuthTokenToLocalStorage(data as string);
        setUserIsProbablyLoggedCookie(!!data);
        clearUnloggedId();
      });
    updateMetadata({ componentSource: SignupComponentSource.UnloggedDefault });
  };

  if (state.authKind === AuthKind.RESET_PASS) {
    return (
      <Recovery
        handleRequestPasswordReset={handleRequestPasswordReset}
        defaultEmail={state.email}
        onGoBack={() =>
          setState((previousState) => ({
            ...previousState,
            authKind: AuthKind.LOGIN_BETA,
          }))
        }
      />
    );
  }

  return (
    <Login
      handleBasicLogin={handleBasicLogin}
      handleBasicSignup={handleBasicSignup}
      modalTitle={modalTitle}
      modalSubtitle={modalSubtitle}
      componentSourceName={componentSourceName}
      handleAuthModal={setState}
      handleFacebookLogin={() => handleFacebookAuth(AuthKind.LOGIN)}
      handleGoogleLogin={() => handleGoogleAuth(AuthKind.LOGIN)}
      handleAppleLogin={() => handleAppleAuth(AuthKind.LOGIN)}
      updateEmail={handleUpdateEmail}
      onCloseModal={onCloseModal}
      onFinishSuccessfully={() => {
        onCloseModal();
        onFinishSuccessfully?.();
        getLoggedUserHelper();
      }}
      getAuthProviders={handleGetAuthProviders}
      handleRequestPasswordReset={handleRequestPasswordReset}
      defaultProvider={defaultProvider}
      isLogged
      googleClientId={googleClientId}
    />
  );
}

export default AuthModal;
