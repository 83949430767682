import {
  MediaQuery,
  H2Heavy,
  Button,
  BodyLight,
  Colors,
  Theme,
  Icon,
} from '@pelando/components';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  picture {
    width: auto;
  }
`;

export const Subtitle = styled.p`
  ${BodyLight}
  color: rgb(${Colors.Gray});
  text-align: center;
  text-decoration: none;
  margin-top: 8px;
  margin-bottom: 16px;
  max-width: 327px;
`;

export const Title = styled.p`
  ${H2Heavy}
  color: rgb(${Theme.colors.Alpha});
  text-align: center;
  text-decoration: none;
  max-width: 327px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    ${H2Heavy}
  }
`;

export const CustomButton = styled(Button)`
  min-width: 290px;
  margin-bottom: 8px;
`;

export const ErrorIcon = styled(Icon)`
  font-size: 48px;
  color: rgba(${Theme.colors.Brand});
  margin-bottom: 16px;
`;
