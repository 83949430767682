import {
  CashbackStatus,
  type CashbackTransaction,
  CashbackUserInfo,
  CashbackWithdrawal,
  WithdrawalStatus,
} from '@/domain/entities/Cashback';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { Clock, CircleCheck, Ban, MoneyWave } from '@pelando/fontawesome/icons';
import { CashbackItem } from '../CashbackItem';
import { useCashbackDetailsModal } from '../CashbackDetailsModal';

import { CashbackItemButton, Container, List, Title } from './style';

export type CashbackHistoryProps = {
  items: CashbackTransaction[];
};

const CashBackIcon = {
  [CashbackStatus.PENDING]: Clock,
  [CashbackStatus.APPROVED]: CircleCheck,
  [CashbackStatus.DECLINED]: Ban,
};

const CashBackRedeemLabel = {
  [WithdrawalStatus.SENT]: 'cashback-redeem-label-sent',
  [WithdrawalStatus.PENDING]: 'cashback-redeem-label-pending',
  [WithdrawalStatus.DECLINED]: 'cashback-redeem-label-declined',
};

export const CashbackHistory = ({ items = [] }: CashbackHistoryProps) => {
  const { showDetailsModal } = useCashbackDetailsModal();
  const { t } = useTranslation('cashbackBalance');

  return (
    <Container>
      <Title>{t('cashback-history-title')}</Title>
      <List>
        {items.map((cashback) => {
          const isCashbackInfo = 'cashbackValue' in cashback;

          const {
            store,
            registeredAt,
            cashbackStatus,
            cashbackValue,
            withdrawalStatus,
            value,
          } = cashback as CashbackUserInfo & CashbackWithdrawal;

          const storeName = isCashbackInfo
            ? store?.name ?? ''
            : t(CashBackRedeemLabel[withdrawalStatus]);
          const status = isCashbackInfo ? cashbackStatus : withdrawalStatus;
          const transactionValue = isCashbackInfo ? cashbackValue : value;
          const icon = isCashbackInfo
            ? CashBackIcon[status as CashbackStatus]
            : MoneyWave;

          return (
            <li key={cashback.id}>
              <CashbackItemButton
                aria-label={`${t(
                  'cashback-item-see-details-button-label'
                )} ${storeName}`}
                onClick={() => showDetailsModal(cashback)}
              >
                <CashbackItem
                  key={registeredAt}
                  store={storeName}
                  date={registeredAt}
                  value={transactionValue}
                  status={status}
                  icon={icon}
                  type={isCashbackInfo}
                />
              </CashbackItemButton>
            </li>
          );
        })}
      </List>
    </Container>
  );
};
