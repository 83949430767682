import React, { useEffect, useState } from 'react';
import { FireFlameCurved } from '@pelando/fontawesome/icons';
import {
  Colors,
  DealDispatcher,
  DealDispatcherPayload,
  DealVoteAction,
  MediaQuery,
  VoteKind,
} from '@pelando/components';
import { CSSProperties } from 'styled-components';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { addOnboardingStageLocalStorage } from '@/infra/storage/onboardingManagement';
import useMediaQuery from '@/presentation/hooks/mediaQuery';
import {
  CardImageContainer,
  Container,
  VoteButton,
  BACKGROUND_COLOR_VAR,
  CardImageHot,
  TooltipContent,
  TooltipDetail,
  TooltipVoteState,
} from './style';
import Translation from '../../Translation';
import {
  HeaderIcon,
  OnboardingSubtitle,
  OnboardingTextContainer,
  OnboardingTitle,
} from '../style';
import { StepTypes, emitOnboardingEvent } from '../consts';

export enum BackgroundColor {
  hot = Colors.OrangePelando,
}

export type VotingStepType = {
  onClickContinue: () => void;
  loggedUserId: string;
};

type TemperatureAnimation = {
  hotAnimation?: string;
};

const VotingStepNew = ({ onClickContinue, loggedUserId }: VotingStepType) => {
  const { t } = useTranslation('onboarding');
  const isMobile = useMediaQuery(MediaQuery.SCREEN_MD_DOWN);

  const cardImage = isMobile
    ? '/assets/onboardingNew/deal-card-mobile-hot.png'
    : '/assets/onboardingNew/deal-card-hot.png';

  const [temperature, setTemperature] = useState(580);
  const [voteInfo, setVoteInfo] = useState({
    voted: false,
    kind: VoteKind.INCREASE,
  });
  const [temperatureAnimation, setTemperatureAnimation] =
    useState<TemperatureAnimation>({
      hotAnimation: '',
    });

  const votingTitleHot = isMobile ? (
    <>
      <Translation translation={t('onboarding-voting-step-hot-title-mobile')}>
        Quanto mais <br /> quente, melhor!
      </Translation>
    </>
  ) : (
    <>{t('onboarding-voting-step-hot-title')}</>
  );

  const votingSubtitleHot = (
    <Translation hasTag translation={t('onboarding-voting-step-hot-subtitle')}>
      O preço está bom? <strong>Clique no foguinho para esquentar.</strong>
    </Translation>
  );

  useEffect(() => {
    addOnboardingStageLocalStorage(StepTypes.VOTING_STEP);
    emitOnboardingEvent(StepTypes.VOTING_STEP, loggedUserId);
  }, [loggedUserId]);

  const getBackgroundColor = (color: BackgroundColor): CSSProperties => ({
    [BACKGROUND_COLOR_VAR as string]: color,
  });

  const handleDispatch: DealDispatcher = ({ payload }) => {
    const { voteKind } = payload as DealDispatcherPayload<DealVoteAction>;

    if (voteKind === VoteKind.INCREASE) {
      setVoteInfo({
        voted: true,
        kind: voteKind,
      });

      setTemperature(Math.round(temperature * 1.2));
      setTemperatureAnimation({ hotAnimation: 'true' });
      setTimeout(() => {
        onClickContinue();
      }, 1500);
    }
  };

  return (
    <Container
      hotContainer={temperatureAnimation.hotAnimation}
      style={getBackgroundColor(BackgroundColor.hot)}
    >
      <OnboardingTextContainer>
        <HeaderIcon icon={FireFlameCurved} />
        <OnboardingTitle>{votingTitleHot}</OnboardingTitle>
        <OnboardingSubtitle>{votingSubtitleHot}</OnboardingSubtitle>
      </OnboardingTextContainer>
      <CardImageContainer>
        <CardImageHot
          voted={voteInfo.voted}
          style={getBackgroundColor(BackgroundColor.hot)}
          src={cardImage}
          height={isMobile ? 178 : 144}
          width={isMobile ? 343 : 701}
        />
        <VoteButton
          id=""
          temperature={temperature}
          voteInfo={voteInfo}
          dispatch={handleDispatch}
          disableIncrease
        />
        {voteInfo.voted ? null : (
          <>
            <TooltipDetail data-state={TooltipVoteState.HOT} />
            <TooltipContent data-state={TooltipVoteState.HOT}>
              <Translation
                translation={t('onboarding-voting-step-tooltip-hot')}
                hasTag
              >
                <strong>Clique aqui</strong>
                para <br />
                esquentar essa promoção
              </Translation>
            </TooltipContent>
          </>
        )}
      </CardImageContainer>
    </Container>
  );
};

export default VotingStepNew;
