import { useCallback } from 'react';
import { useModal } from '@pelando/components';
import {
  CashbackDescriptionMap,
  CashbackStatus,
  CashbackStatusMap,
  CashbackUserInfo,
  CashbackWithdrawal,
  PreviousStatus,
  WithdrawalPreviousStatus,
  WithdrawalStatus,
  type CashbackTransaction,
} from '@/domain/entities/Cashback';
import { LockScrollGlobally } from '@/presentation/styles/global';
import { getCurrencyStringByValue } from '@/presentation/services/strings';
import {
  getContactPelandoUrl,
  getPelandoHelpCenterUrl,
} from '@/presentation/services/urls';
import { useTranslation } from '@/presentation/hooks/useTranslation';

import { Ban, CircleCheck, Clock } from '@pelando/fontawesome/icons';

import { useRouter } from 'next/router';
import {
  modalStyle,
  Container,
  StoreImage,
  Header,
  StoreName,
  Value,
  PurchaseInfo,
  History,
  GoBackButton,
  Footer,
  InfoLink,
  PendingIcon,
  SuccessPurchaseIcon,
  HistoryItem,
  ContactButton,
} from './style';

type CashbackDetailsModalProps = {
  cashbackInfo: CashbackTransaction;
  onGoBack: () => void;
};

const REDEEM_IMAGE = '/assets/cashbackBalance/withdraw-icon.png';

export const CashbackDetailsModal = ({
  cashbackInfo,
  onGoBack,
}: CashbackDetailsModalProps) => {
  const { push, locale } = useRouter();
  const { t } = useTranslation('cashbackBalance');
  const isCashbackInfo = 'cashbackValue' in cashbackInfo;
  const cashbackType = isCashbackInfo ? 'cashback' : 'withdrawal';

  const {
    store,
    cashbackValue,
    cashbackPercentage,
    totalValue,
    registeredAt,
    previousStatus,
    value: withdrawValue,
  } = cashbackInfo as CashbackUserInfo & CashbackWithdrawal;

  const storeImage = isCashbackInfo ? store?.image?.url : REDEEM_IMAGE;
  const storeName = isCashbackInfo ? store?.name : t('withdrawal-modal-name');
  const formattedRegisteredAt = new Date(registeredAt).toLocaleDateString();
  const formattedTotalValue =
    totalValue ?? getCurrencyStringByValue(totalValue);
  const formattedCashbackValue = getCurrencyStringByValue(
    isCashbackInfo ? cashbackValue : withdrawValue
  );

  const getStatus = (storeName: string, status: CashbackStatus) => {
    if (status !== CashbackStatus.PENDING && isCashbackInfo)
      return `${t('cashback-item-prefix')} ${storeName} ${t(
        CashbackDescriptionMap[cashbackType][status]
      )}`;

    return t(CashbackDescriptionMap[cashbackType][status]);
  };

  const CashBackIcon = {
    [CashbackStatus.PENDING]: Clock,
    [CashbackStatus.APPROVED]: CircleCheck,
    [CashbackStatus.DECLINED]: Ban,
  };

  const statusMap: Record<WithdrawalStatus, CashbackStatus> = {
    [WithdrawalStatus.PENDING]: CashbackStatus.PENDING,
    [WithdrawalStatus.DECLINED]: CashbackStatus.DECLINED,
    [WithdrawalStatus.SENT]: CashbackStatus.APPROVED,
  };

  const normalizedStatus = (
    status: CashbackStatus | WithdrawalStatus
  ): CashbackStatus =>
    statusMap[status as WithdrawalStatus] ?? (status as CashbackStatus);

  const normalizedPreviousStatus = previousStatus.map(
    (status: PreviousStatus | WithdrawalPreviousStatus) => ({
      status: normalizedStatus(
        'cashbackStatus' in status
          ? status.cashbackStatus
          : status.withdrawalStatus
      ),
      registeredAt: status.registeredAt,
    })
  );

  const isCashbackCanceled = normalizedPreviousStatus.some(
    (status) => status.status === CashbackStatus.DECLINED
  );

  return (
    <Container>
      <Header>
        <StoreImage src={storeImage} alt={storeName} />
        <StoreName>{storeName}</StoreName>
        <Value>{formattedCashbackValue}</Value>
        {isCashbackInfo ? (
          <PurchaseInfo>
            {cashbackPercentage}% {t('cashback-details-percentage-preposition')}{' '}
            {formattedTotalValue}
          </PurchaseInfo>
        ) : null}
      </Header>
      <History>
        {normalizedPreviousStatus
          ?.map((status, index) => {
            const { status: currentStatus, registeredAt } = status;

            const normalizedCurrentStatus = normalizedStatus(currentStatus);

            const pendingApproved =
              normalizedCurrentStatus === CashbackStatus.PENDING &&
              previousStatus.length - 1 !== index;

            const pendingStatus = pendingApproved
              ? CashbackStatus.APPROVED
              : CashbackStatus.PENDING;

            const iconStatus =
              normalizedCurrentStatus === CashbackStatus.PENDING
                ? pendingStatus
                : normalizedCurrentStatus;

            return (
              <HistoryItem key={registeredAt}>
                <PendingIcon
                  status={iconStatus}
                  icon={CashBackIcon[iconStatus]}
                />
                <strong>
                  {t(CashbackStatusMap[cashbackType][normalizedCurrentStatus])}
                </strong>
                <span>{getStatus(storeName, currentStatus)}</span>
                <span>{new Date(registeredAt).toLocaleDateString()}</span>
              </HistoryItem>
            );
          })
          .reverse()}
        {isCashbackInfo ? (
          <HistoryItem>
            <SuccessPurchaseIcon icon={CircleCheck} />
            <strong>{t('cashback-item-purchase-completed-text')}</strong>
            <span>{formattedRegisteredAt}</span>
          </HistoryItem>
        ) : null}
      </History>
      <Footer>
        <GoBackButton onClick={onGoBack}>
          {t('cashback-details-modal-go-back-button-label')}
        </GoBackButton>
        {isCashbackCanceled ? (
          <ContactButton
            light
            onClick={() => push(getContactPelandoUrl(locale))}
          >
            {t('cashback-details-modal-contact-button-label')}
          </ContactButton>
        ) : (
          <InfoLink
            href={getPelandoHelpCenterUrl()}
            rel="noreferrer"
            target="_blank"
          >
            {t('cashback-details-modal-help-center-link-label')}
          </InfoLink>
        )}
      </Footer>
    </Container>
  );
};

export const useCashbackDetailsModal = () => {
  const { showModal, closeModal: closeDetailsModal } = useModal();

  const showDetailsModal = useCallback(
    (cashbackInfo: CashbackTransaction) => {
      showModal(
        <>
          <CashbackDetailsModal
            cashbackInfo={cashbackInfo}
            onGoBack={closeDetailsModal}
          />
          <LockScrollGlobally lock />
        </>,
        {
          style: modalStyle,
        }
      );
    },
    [closeDetailsModal, showModal]
  );

  return {
    showDetailsModal,
    closeDetailsModal,
  };
};
