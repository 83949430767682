export const CASHBACKS_FRAGMENT = `
fragment CashbackUserInfoFragment on CashbackUserInfo {
    id
    cashbackStatus: status
    cashbackValue
    totalValue
    cashbackPercentage
    registeredAt
    store {
          id
          name
           image {
            id
            url(width:60 height:60)
        }
        }
    previousStatus  {
        cashbackStatus: status
        registeredAt
    }  
}
`;

export const CASHBACKS_WITHDRAWAL_FRAGMENT = `
fragment CashbackWithdrawalFragment on CashbackWithdrawal {
    id
    withdrawalStatus: status
    value
    registeredAt
    previousStatus {
        withdrawalStatus: status
        registeredAt
    }
}
`;
