import { Store } from './Store';

export enum PixKeyType {
  CPF = 'CPF',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  RANDOM = 'RANDOM',
}

export enum CashbackStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
}

export enum WithdrawalStatus {
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  SENT = 'SENT',
}

export type WithdrawalPreviousStatus = {
  withdrawalStatus: WithdrawalStatus;
  registeredAt: string;
};

export type CashbackWithdrawal = {
  id: string;
  withdrawalStatus: WithdrawalStatus;
  value: number;
  registeredAt: string;
  previousStatus: WithdrawalPreviousStatus[];
};

export type CashbackUserInfo = {
  id: string;
  cashbackValue: number;
  cashbackPercentage: number;
  totalValue: number;
  registeredAt: string;
  cashbackStatus: CashbackStatus;
  store: Store;
  previousStatus: PreviousStatus[];
};

export type CashbackTransaction = CashbackUserInfo | CashbackWithdrawal;

export const CashbackStatusMap: Record<
  'cashback' | 'withdrawal',
  Record<string, string>
> = {
  cashback: {
    [CashbackStatus.PENDING]: 'cashback-item-pending-text',
    [CashbackStatus.APPROVED]: 'cashback-item-approved-text',
    [CashbackStatus.DECLINED]: 'cashback-item-declined-text',
  },
  withdrawal: {
    [WithdrawalStatus.PENDING]: 'withdrawal-item-pending-text',
    [WithdrawalStatus.SENT]: 'withdrawal-item-sent-text',
    [CashbackStatus.APPROVED]: 'withdrawal-item-sent-text',
    [WithdrawalStatus.DECLINED]: 'withdrawal-item-declined-text',
  },
};

export const CashbackDescriptionMap: Record<
  'cashback' | 'withdrawal',
  Record<string, string>
> = {
  cashback: {
    [CashbackStatus.PENDING]: 'cashback-item-pending-description',
    [CashbackStatus.APPROVED]: 'cashback-item-success-description',
    [CashbackStatus.DECLINED]: 'cashback-item-declined-description',
  },
  withdrawal: {
    [WithdrawalStatus.PENDING]: 'withdrawal-item-pending-description',
    [WithdrawalStatus.SENT]: 'withdrawal-item-sent-description',
    [CashbackStatus.APPROVED]: 'withdrawal-item-sent-description',
    [WithdrawalStatus.DECLINED]: 'withdrawal-item-declined-description',
  },
};

export type PreviousStatus = {
  cashbackStatus: CashbackStatus;
  registeredAt: string;
};

export type CashbackTip = {
  question: string;
  answer: string;
};
