import { User } from '@/domain/entities/User';
import { getLoggedUser } from '../auth/endpoints';
import { createNewEndpoint } from '../core/endpoint';
import { GraphQlMethods } from '../core/fetch';
import { AuthGuard } from '../core/guards/authGuard';
import { CashbacksActionType } from './actionTypes';
import { UPDATE_BANK_ACCOUNT_MUTATION } from './mutations/updateBankAccountDetails';
import { GET_CASHBACK_AVAILABLE_BALANCE } from './queries/getCashbackAvailableBalance';
import { GET_CASHBACKS_QUERY } from './queries/getCashbacks';
import {
  CashbackAvailableBalanceResponse,
  CashbackBalanceAvailableCache,
  CashbackAvailableBalance,
} from './types/getCashbackAvailableBalance';
import {
  CashbackCache,
  Cashbacks,
  CashbacksResponse,
  CashbacksVariables,
} from './types/getCashbacks';
import {
  BankAccountDetails,
  UpdateBankAccountCache,
  UpdateBankAccountResponse,
  UpdateBankAccountVariables,
} from './types/updateBankAccount';
import { AuthenticationStatus } from '../auth/types/GetLoggedUser';

export const getCashbacks = createNewEndpoint<
  CashbacksResponse,
  CashbacksVariables,
  Cashbacks,
  CashbackCache
>({
  id: CashbacksActionType.GetCashbacks,
  query: GET_CASHBACKS_QUERY,
  method: GraphQlMethods.Get,
  transform: (result) => result.me.cashbackTransactions,
  cacheTransform: ({ data, loading }) => ({
    loading,
    cashbacks: data?.edges,
  }),
});

export const getCashbackAvailableBalance = createNewEndpoint<
  CashbackAvailableBalanceResponse,
  void,
  CashbackAvailableBalance,
  CashbackBalanceAvailableCache
>({
  id: CashbacksActionType.GetCashbackAvailableBalance,
  query: GET_CASHBACK_AVAILABLE_BALANCE,
  method: GraphQlMethods.Get,
  transform: (result) => result.me.cashbackAvailableBalance,
  cacheTransform: ({ data, loading }) => ({
    loading,
    cashbackAvailableBalance: data,
    guards: [AuthGuard],
  }),
});

export const updateBankAccountDetails = createNewEndpoint<
  UpdateBankAccountResponse,
  UpdateBankAccountVariables,
  BankAccountDetails,
  UpdateBankAccountCache
>({
  id: CashbacksActionType.UpdateBankAccountDetails,
  query: UPDATE_BANK_ACCOUNT_MUTATION,
  method: GraphQlMethods.Post,
  transform: (result) => result.me.updateBankAccountDetails.bankAccountDetails,
  cacheTransform: ({ data, loading }) => {
    if (data) {
      getLoggedUser.cache$.next({
        loggedUser: {
          ...(getLoggedUser.cache$.value?.loggedUser as User),
          bankAccountDetails: data,
        },
        loading: getLoggedUser.cache$.value?.loading as boolean,
        status: getLoggedUser.cache$.value?.status as AuthenticationStatus,
      });
    }
    return {
      loading,
      bankAccountDetails: data,
    };
  },
});
